import { XIcon } from '@primer/octicons-react';
import { useEffect } from 'react';
import styled from 'styled-components';

export interface AlertProps {
  title: string;
  message: string;
  type: 'success' | 'error' | 'warning' | 'info';
  onClose?: () => void;
  show: boolean;
}

const StyledAlert = styled.div`
  .alert {
    position: absolute;
    padding: 5px 10px;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    font-size: 13.5px;
    right: 20px;
    left: 20px;
    top: 20px;
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
    z-index: 9999;
    word-wrap: break-word;
    &-title {
      font-weight: 600;
      font-size: 15px;
    }

    &-message {
      font-size: 13.5px;
      margin-top: 1px;
    }
    &-success {
      color: #155724;
      background-color: #d4edda;
    }
    &-info {
      color: #0c5460;
      background-color: #d1ecf1;
    }
    &-warning {
      color: #856404;
      background-color: #fff3cd;
    }
    &-close {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 5px;
    }
    &-danger,
    &-error {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
    }
  }
`;

const Alert = ({ title, message, type, onClose, show }: AlertProps) => {
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        onClose && onClose();
      }, 8000);
    }
  }, [show, onClose]);

  return show ? (
    <StyledAlert>
      <div className={`alert alert-${type}`}>
        <div className="alert-title">{title}</div>
        <div className="alert-message">{message}</div>
        <div className="alert-close" onClick={onClose}>
          <XIcon size={16} />
        </div>
      </div>
    </StyledAlert>
  ) : null;
};

export default Alert;
