import { VerifiedIcon } from '@primer/octicons-react';
import { GeneralStatsSkeleton } from 'components/skeletons';
import { CoreContext } from 'contexts';
import { Layout } from 'layouts';
import { useContext, useEffect, useState } from 'react';
import { Line, Pie } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import { statsService } from 'services';
import { lineData, lineOptions, pieData } from './components';
import { MonthlyStats, SchoolItemGeneralStats, StudentStats } from 'services/stats/index.models';

export const SchoolHome = () => {
  const { user } = useContext(CoreContext);
  const [generalStats, setGeneralStats] = useState<SchoolItemGeneralStats>({
    students: 0,
    classes: 0,
    parents: 0,
    teachers: 0
  });
  const [monthlyStats, setMonthlyStats] = useState<MonthlyStats>({
    schools: [],
    classes: [],
    students: [],
    parents: [],
    teachers: []
  });
  const [studentsStats, setStudentsStats] = useState<StudentStats>({
    total: 0,
    male: 0,
    female: 0,
    other: 0,
    none: 0
  });
  const [loaders, setLoaders] = useState({
    generalStats: true,
    monthlyStats: true,
    studentsStats: true,
    parentsStats: true
  });

  useEffect(() => {
    (async () => {
      try {
        if (user) {
          await statsService.getSchoolGeneralStats(user.uid, res => {
            setGeneralStats(res);
            setLoaders(prev => ({ ...prev, generalStats: false }));
          });

          await statsService.getSchoolMonthlyStats(user.uid, res => {
            setMonthlyStats(res);
            setLoaders(prev => ({ ...prev, monthlyStats: false }));
          });
          await statsService.getSchoolStudentStats(user.uid, res => {
            setStudentsStats(res);
            setLoaders(prev => ({ ...prev, studentsStats: false }));
          });
        }
      } catch (error) {
        if (error instanceof Error) toast.error(error.message);
      }
    })();
  }, [user]);

  return (
    <Layout className="home">
      <section className="home-content">
        <section className="stats">
          <div className="stats-header">
            <div className="home-title">
              {user?.emailVerified ? <VerifiedIcon size={24} /> : ''}
              {user?.displayName ?? 'Dashboard'}
            </div>
            <small className="stats-subtitle">Total number of classes, teachers, students and parents</small>
          </div>
          <div className="stats-title">General Stats</div>

          {loaders.generalStats ? (
            <GeneralStatsSkeleton />
          ) : (
            <section className="dashboard-data">
              <div className="dashboard-data-card">
                <div className="dashboard-data-card-title">Classes</div>
                <div className="dashboard-data-card-value">{generalStats.classes}</div>
              </div>
              <div className="dashboard-data-card">
                <div className="dashboard-data-card-title">Teachers</div>
                <div className="dashboard-data-card-value">{generalStats.teachers}</div>
              </div>
              <div className="dashboard-data-card">
                <div className="dashboard-data-card-title">Students</div>
                <div className="dashboard-data-card-value">{generalStats.students}</div>
              </div>
              <div className="dashboard-data-card">
                <div className="dashboard-data-card-title">Parents</div>
                <div className="dashboard-data-card-value">{generalStats.parents}</div>
              </div>
            </section>
          )}
        </section>
        <section className="stats">
          <div className="stats-title">Enrollment Stats</div>
          <section className="stats-charts">
            <div className="stats-chart">
              <Line
                options={lineOptions}
                data={{
                  ...lineData,
                  labels: monthlyStats.students.map(m => m.month),
                  datasets: [
                    {
                      ...lineData.datasets[0],
                      data: monthlyStats.students.map(m => m.total)
                    },
                    {
                      ...lineData.datasets[1],
                      data: monthlyStats.classes.map(m => m.total)
                    },
                    {
                      ...lineData.datasets[2],
                      data: monthlyStats.teachers.map(m => m.total)
                    }
                  ]
                }}
              />
            </div>
            <div className="stats-chart pie-chart">
              <div className="stats-chart-subtitle">Students(Gender Stats)</div>
              <Pie
                data={{
                  ...pieData,
                  datasets: [
                    {
                      ...pieData.datasets[0],
                      data: [
                        studentsStats.female,
                        studentsStats.male,
                        studentsStats.total - (studentsStats.female + studentsStats.male)
                      ]
                    }
                  ]
                }}
              />
            </div>
          </section>
        </section>
      </section>
    </Layout>
  );
};
