import { ManageType } from './index.models';

export const SCHOOL_TABS = [
  {
    label: 'Account',
    id: ManageType.Account
  },
  {
    label: 'Exams',
    id: ManageType.Exams
  },
  {
    label: 'Curriculum',
    id: ManageType.Curriculum
  }
  // {
  //   label: 'Users',
  //   id: ManageType.Users
  // }
];

export const ADMIN_TABS = [
  {
    label: 'Account',
    id: ManageType.Account
  }
  // {
  //   label: 'Users',
  //   id: ManageType.Users
  // }
];
