import { CalendarIcon, GearIcon, HomeIcon, NoteIcon, SignInIcon, TableIcon } from '@primer/octicons-react';
import { CoreContext } from 'contexts';
import { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { coreService } from '../../services';
import styled from 'styled-components';

const StyledSidebar = styled.aside`
  height: 100vh;
  width: 250px;
  background: var(--bg-color);
  border-right: 1px solid var(--border-color);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;

  .sidebar {
    &-header {
      display: flex;
      flex-direction: column;
      height: 60px;
      padding: 10px 20px;
      border-bottom: 1px solid var(--border-color);
      font-weight: 700;
      small {
        color: var(--success-600);
        font-size: 12px;
        font-weight: 500;
      }
    }

    &-menu ul {
      font-weight: 500;
      padding: 0;
      margin: 0;
      list-style: none;
    }

    &-menu-item {
      display: flex;
      align-items: center;
      padding: 18px 20px;
      font-size: 14px;
      color: var(--text-color);
      text-decoration: none;
      border-bottom: 1px solid var(--border-color);
    }

    &-menu-link {
      display: flex;
      align-items: center;
      width: 100%;
      color: inherit;
      text-decoration: none;
      gap: 10px;
    }

    &-menu-link:hover {
      color: var(--hover-color);
    }

    &-menu-link.active-link {
      color: var(--hover-color);
    }

    &-footer {
      padding: 10px 20px;
      border-top: 1px solid var(--border-color);
      font-size: 13px;
      color: var(--gray-700);
    }
  }
`;

const Sidebar = () => {
  const { role, profile, user, availableFeatures } = useContext(CoreContext);
  const [links, setLinks] = useState<
    {
      name: string;
      icon: any;
      path: string;
      slug: string;
      disabled: boolean;
      show: boolean;
    }[]
  >([]);

  useEffect(() => {
    setLinks(
      role === 'admin'
        ? [
            {
              name: 'Home',
              icon: <HomeIcon />,
              path: '/',
              slug: 'home',
              disabled: false,
              show: true
            },
            {
              name: 'Schools',
              icon: <TableIcon />,
              path: '/schools',
              slug: 'schools',
              disabled: false,
              show: true
            },
            {
              name: 'Manage',
              icon: <GearIcon />,
              path: '/manage',
              slug: 'manage',
              disabled: false,
              show: true
            },
            {
              name: 'Sign out',
              icon: <SignInIcon />,
              path: '/auth',
              slug: 'signout',
              disabled: true,
              show: true
            }
          ]
        : [
            {
              name: 'Home',
              icon: <HomeIcon />,
              path: '/',
              slug: 'home',
              disabled: false,
              show: true
            },
            {
              name: 'Classes',
              icon: <TableIcon />,
              path: '/classes',
              slug: 'classes',
              disabled: false,
              show: true
            },
            {
              name: 'Calendar',
              icon: <CalendarIcon />,
              path: '/calendar',
              slug: 'calendar',
              disabled: false,
              show: availableFeatures.includes('calendar')
            },
            {
              name: 'Newsletters',
              icon: <NoteIcon />,
              path: '/newsletters',
              slug: 'newsletters',
              disabled: false,
              show: availableFeatures.includes('newsletters')
            },
            {
              name: 'Manage',
              icon: <GearIcon />,
              path: '/manage',
              slug: 'manage',
              disabled: false,
              show: true
            },
            {
              name: 'Sign out',
              icon: <SignInIcon />,
              path: '/auth',
              slug: 'signout',
              disabled: true,
              show: true
            }
          ]
    );
  }, [availableFeatures, role]);

  return (
    <StyledSidebar>
      <div className="sidebar-top">
        <div className="sidebar-header">
          <div>{role === 'admin' ? 'Recess Central' : profile?.name}</div>
          <small>Logged in as {role === 'admin' ? 'an admin' : 'a school'}</small>
        </div>
        <div className="sidebar-menu">
          <ul>
            {links
              .filter(item => item.show)
              .map(link => (
                <li className="sidebar-menu-item" key={link.slug}>
                  {link.disabled && link.slug === 'signout' ? (
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        coreService.signOut();
                      }}
                      className="sidebar-menu-link"
                    >
                      <span className="sidebar-menu-icon">{link.icon}</span>
                      <span>{link.name}</span>
                    </div>
                  ) : (
                    <NavLink
                      className={({ isActive, isPending }) =>
                        isActive ? 'sidebar-menu-link active-link' : 'sidebar-menu-link'
                      }
                      to={link.path}
                    >
                      <span className="sidebar-menu-icon">{link.icon}</span>
                      <span>{link.name}</span>
                    </NavLink>
                  )}
                </li>
              ))}
          </ul>
        </div>
      </div>
      <div className="sidebar-footer">
        <small>Last login: {user?.metadata.lastSignInTime}</small>
      </div>
    </StyledSidebar>
  );
};

export default Sidebar;
