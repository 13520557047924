import { ExamItem } from 'services/exams/index.models';

export enum EPermission {
  Read = 0,
  Write = 1,
  Read_Write = 2
}

export enum ERole {
  Admin = 'admin',
  School = 'school',
  Teacher = 'teacher',
  Parent = 'parent'
}

export interface IAdmin {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  permission: EPermission;
  date_created?: string;
  date_updated?: string;
}

export enum ManageType {
  Account = 1,
  Exams = 2,
  Users = 3,
  Curriculum = 4
}

export enum UserRole {
  Admin = 1,
  Parent = 2,
  School = 3,
  Teacher = 4,
  SuperAdmin = 5
}

export enum EAdminRoleScope {
  Recess = 1,
  School = 2,
  Partner = 3
}

export enum SchoolRoles {
  Classes = 'classes',
  Newsletters = 'newsletters',
  Results = 'results',
  Fees = 'fees',
  Attendance = 'attendance',
  Calendar = 'calendar',
  Library = 'library',
  Manage = 'manage',
  Exams = 'exams',
  Curriculum = 'curriculum'
}

export interface ExamData {
  name: string;
  id: string;
  data: {
    id: string;
    name: string;
    exams: ExamItem[];
  }[];
}

export enum SchoolFeatures {
  Calendar = 'calendar',
  Newsletters = 'newsletters',
  Results = 'results',
  Fees = 'fees',
  Library = 'library',
  Attendance = 'attendance'
}

export interface OptionItem {
  label: string;
  value: string | number;
}
