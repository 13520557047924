import { SignOutIcon } from '@primer/octicons-react';
import { CSSProperties, useState } from 'react';
import { coreService } from 'services';
import styled from 'styled-components';
const StyledLogOut = styled.div``;
const StyledGroupedBtn = styled.div`
  .btn-grouped {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid var(--border-color);
    border-radius: 7px;
    padding: 1px;
    gap: 1px;
    &-item {
      background-color: var(--btn-bg-secondary);
      color: var(--btn-text-primary-color);
      cursor: pointer;
      outline: 0;
      border: 0;
      padding: 8px 10px;
      min-width: 100px;
      border-radius: 0;
      font-size: 0.9rem;
      user-select: none;

      &:hover {
        color: var(--btn-text-color);
        background-color: var(--btn-active-bg-color);
      }
    }

    &-item:active,
    &-item-active {
      background-color: var(--btn-active-bg-color);
      color: var(--btn-text-color);
    }

    &-item:first-child {
      border-top-left-radius: 7px;
      border-bottom-left-radius: 7px;
    }

    &-item:last-child {
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
    }
  }

  @media screen and (max-width: 600px) {
    .btn-grouped {
      margin: 10px 0;
    }

    .btn-grouped-item {
      min-width: 100px;
      display: flex;
      font-size: 13px;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
    }
  }
`;

const Logout = () => {
  const handleLogout = () => {
    coreService.signOut();
  };
  return (
    <StyledLogOut>
      <div className="header-avatar" onClick={handleLogout}>
        <SignOutIcon size={24} />
      </div>
    </StyledLogOut>
  );
};

const GroupedButtons = ({
  style = {},
  className = '',
  buttons = [
    {
      label: 'Sales',
      type: 'button'
    },
    {
      label: 'Payouts',
      type: 'button'
    }
  ],
  clickHandler = (label: string) => {
    console.log(label);
  }
}: {
  className?: string;
  buttons?: { label: string; type?: 'button' | 'button' | 'reset' }[];
  clickHandler?: (label: string) => void;
  style?: CSSProperties;
}) => {
  const [active, setActive] = useState(0);
  return (
    <StyledGroupedBtn>
      <div style={style} className={`btn-grouped ${className}`}>
        {buttons.map((item, index) => (
          <button
            type={item.type}
            key={`btn-${index}-${item.label}`}
            className={`btn-grouped-item ${active === index ? 'btn-grouped-item-active' : ''}`}
            onClick={event => {
              event.preventDefault();
              setActive(index);
              clickHandler(item.label.trim().split(' ').join('-').toLowerCase());
            }}
          >
            {item.label}
          </button>
        ))}
      </div>
    </StyledGroupedBtn>
  );
};

export { GroupedButtons, Logout };
