import { LineLoader } from 'components';
import { AlertProps } from 'components/alert';
import { User } from 'firebase/auth';
import { CurriculumItem } from 'models/index.model';
import { SchoolItem } from 'pages/schools/models/index.models';
import { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { coreService, dbService } from 'services';
import { examService } from 'services/exams';
import { ExamItem } from 'services/exams/index.models';

export type UserRole = 'admin' | 'school' | null;

interface ICoreContext {
  user: User | null;
  loading: boolean;
  showLineLoader: boolean;
  setShowLineLoader: (show: boolean) => void;
  setLoading: (loading: boolean) => void;
  showAlert: AlertProps;
  setShowAlert: (showAlert: AlertProps) => void;
  curriculums: CurriculumItem[];
  setCurriculum: (curriculum: string) => void;
  activeCurriculum: CurriculumItem | null;
  role: UserRole;
  setRole: (role: UserRole) => void;
  examList: ExamItem[];
  setExamList: (examList: ExamItem[]) => void;
  profile: SchoolItem | null;
  setProfile: Dispatch<SetStateAction<SchoolItem | null>>;
  availableFeatures: string[];
  setAvailableFeatures: Dispatch<SetStateAction<string[]>>;
}

const CoreContext = createContext<ICoreContext>({
  user: null,
  loading: true,
  setLoading: () => {},
  showAlert: {
    show: false,
    type: 'success',
    title: 'Success',
    message: 'This is a success alert.'
  },
  setShowAlert: () => {},
  showLineLoader: false,
  setShowLineLoader: () => {},
  curriculums: [],
  activeCurriculum: null,
  setCurriculum: () => {},
  role: null,
  setRole: () => {},
  examList: [],
  setExamList: () => {},
  profile: null,
  setProfile: () => {},
  availableFeatures: [],
  setAvailableFeatures: () => {}
});

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [profile, setProfile] = useState<SchoolItem | null>(null);
  const [availableFeatures, setAvailableFeatures] = useState<string[]>([]);
  const [curriculums, setCurriculums] = useState<CurriculumItem[]>([]);
  const [curriculum, setCurriculum] = useState<string>('');
  const [activeCurriculum, setActiveCurriculum] = useState<CurriculumItem | null>(null);
  const [loading, setLoading] = useState(true);
  const [showLineLoader, setShowLineLoader] = useState(false);
  const [role, setRole] = useState<UserRole>(null);
  const [examList, setExamList] = useState<ExamItem[]>([]);
  const [showAlert, setShowAlert] = useState<AlertProps>({
    show: false,
    type: 'success',
    title: 'Success',
    message: 'This is a success alert.'
  });

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        dbService.onAuthStateChanged(dbService.auth, async user => {
          if (user) {
            setUser(user);
            const curriculumsRes = await coreService.getCurriculums();
            setCurriculums(curriculumsRes.data ?? []);
            const res = await coreService.checkUserType(user.email ?? '');
            setRole(res.role);
            if (['admin', 'school'].includes(res.role ?? '')) {
              if (res.role === 'school') {
                const profile = await coreService.getSchoolById(user.uid);
                setProfile(profile);
              }
            } else {
              setUser(null);
              setRole(null);
              await coreService.signOut();
            }
          } else {
            setUser(null);
            setRole(null);
          }
          setLoading(false);
        });
      } catch (error) {
        setLoading(false);
        await coreService.signOut();
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (user && user.uid) {
          await examService.getExams(user.uid, data => {
            setExamList(data);
            setShowLineLoader(false);
          });
        }
      } catch (error) {
        if (error instanceof Error) toast.error(error.message);
        setShowLineLoader(false);
      }
    })();
  }, [user, setShowLineLoader]);

  useEffect(() => {
    if (curriculum) {
      const activeCurriculum = curriculums.find(item => item.slug === curriculum);
      setActiveCurriculum(activeCurriculum ?? null);
    }
  }, [curriculum, curriculums]);

  return (
    <CoreContext.Provider
      value={{
        user,
        loading,
        setLoading,
        showAlert,
        setShowAlert,
        showLineLoader,
        setShowLineLoader,
        curriculums,
        role,
        setRole,
        activeCurriculum,
        setCurriculum,
        examList,
        setExamList,
        profile,
        setProfile,
        availableFeatures,
        setAvailableFeatures
      }}
    >
      <LineLoader show={loading || showLineLoader} hideShadow={loading} />
      {children}
    </CoreContext.Provider>
  );
};

export { AuthProvider, CoreContext };
