import { ArrowUpRightIcon, DotFillIcon } from '@primer/octicons-react';
import { Button, CounterLabel, Label, Tooltip } from '@primer/react';
import { ITeacher } from 'pages/schools/models/index.models';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { coreService } from 'services';
import { ClassItemrowProps, IStudent } from 'services/students/index.models';
import Swal from 'sweetalert2';

const StudentsRow = ({ item }: { item: IStudent }) => {
  return item ? (
    <tr>
      <td>{item.admission_no}</td>
      <td>{item.admission_date.short_date}</td>
      <td>
        <div>{`${item.first_name} ${item.last_name}`}</div>
        <div className="text-muted">{item.gender === 'F' ? 'Female' : item.gender === 'M' ? 'Male' : 'Other'}</div>{' '}
      </td>
      <td>{item && item.class ? item.class.name : 'N/A'}</td>
      <td>
        <div>{item.parents.length && item.parents[0] ? item.parents[0].name : ''}</div>
        <div className="text-muted">{item.parents.length ? item.parents[0].email : ''}</div>
      </td>
    </tr>
  ) : null;
};

const TeachersRow = ({ item, onSelect }: { item: ITeacher; onSelect?: any }) => {
  return item ? (
    <>
      <tr>
        <td className="tb-teacher-name">
          <span>{`${item.first_name} ${item.last_name}`}</span>
          {item.class_teacher.length ? (
            <Tooltip aria-label="Class Teacher" className="tb-dot">
              <DotFillIcon className="tb-dot-active" />
            </Tooltip>
          ) : null}
        </td>
        <td>{item.email}</td>
        <td>
          <span>{item.classes.length ? item.classes[0].name : 'N/A'}</span>
          <span className="tb-more">
            {item.classes.length > 1 ? (
              <>
                <span style={{ marginLeft: 2 }}>+</span>
                <span
                  onClick={event => {
                    event.preventDefault();
                    onSelect(item);
                  }}
                  style={{ cursor: 'pointer', textDecoration: 'underline', marginLeft: 2 }}
                >
                  {item.classes.length - 1} more
                </span>
              </>
            ) : (
              ''
            )}
          </span>
        </td>
        <td>{item.date_created.short_date}</td>
      </tr>
    </>
  ) : null;
};

const ClassesRow = ({ item, school_id, role, setClasses, setEditClass }: ClassItemrowProps) => {
  const navigate = useNavigate();
  return item ? (
    <tr>
      <td>{item.full_name}</td>
      <td>
        <Label>{item.streamed ? 'Yes' : 'No'}</Label>
      </td>
      <td>
        <CounterLabel>{item.subjects.length}</CounterLabel>
      </td>
      <td>
        <div className="btn-actions">
          <Button disabled={true} size="small" onClick={setEditClass}>
            Edit
          </Button>
          <Button
            size="small"
            variant="danger"
            onClick={async () => {
              try {
                await Swal.fire({
                  title: 'Are you sure?',
                  text: 'You will not be able to recover this class',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#dc3545',
                  cancelButtonColor: '#6a737d',
                  confirmButtonText: 'Yes, delete',
                  cancelButtonText: 'No, cancel',
                  allowOutsideClick: false,
                  preConfirm: async () => {
                    try {
                      await coreService.deleteClassById(school_id, item.id, data => {
                        if (data)
                          Swal.fire({
                            title: 'Deleted!',
                            text: data.message,
                            icon: 'success',
                            confirmButtonText: 'Ok, got it!',
                            confirmButtonColor: '#1cbc96'
                          });
                      });
                      await coreService.getClassesBySchoolId(school_id, data => {
                        if (data) setClasses(data);
                      });
                    } catch (error) {
                      if (error instanceof Error) Swal.showValidationMessage(`Error: ${error.message}`);
                    }
                  }
                });
              } catch (error) {
                if (error instanceof Error) toast.error(error.message);
              }
            }}
          >
            Delete
          </Button>
          <Button
            size="small"
            trailingVisual={ArrowUpRightIcon}
            onClick={() => navigate(`${role === 'admin' ? `/schools/${school_id}/` : '/classes/'}${item.id}`)}
          />
        </div>
      </td>
    </tr>
  ) : null;
};

export { ClassesRow, StudentsRow, TeachersRow };
