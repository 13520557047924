import styled from 'styled-components';
const StyledGeneralSkeleton = styled.section`
  .general-stats {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;

    &-item {
      border: 1px solid var(--border-color);
      border-radius: 15px;
      height: 111.59px;
      padding: 1rem;
      &-title,
      &-value {
        margin-bottom: 0.7rem;
        animation: skeleton 1.5s ease-in-out infinite;
        background-color: var(--skeleton-color);
        height: 0.9rem;
        width: 100%;
        max-width: 150px;
        &-value {
          height: 1.5rem;
          max-width: 25px;
          margin-bottom: 0;
        }
      }
    }
  }
`;

const GeneralStatsSkeleton = () => {
  return (
    <StyledGeneralSkeleton>
      <section className="general-stats">
        {[1, 2, 3, 4].map((_, i) => (
          <div key={i} className="general-stats-item">
            <div className="general-stats-item-title"></div>
            <div className="general-stats-item-value"></div>
          </div>
        ))}
      </section>
    </StyledGeneralSkeleton>
  );
};

const StyledNewsLettersSkeleton = styled.section`
  .news-letter {
    padding: 0;
    &-cards,
    &-card {
      display: flex;
      flex-direction: column;
      gap: 10px;
      border: 1px solid var(--border-color);
      border-radius: 15px;
      padding: 1rem;
    }
    &-card {
      &-content,
      &-title {
        animation: skeleton 1.5s ease-in-out infinite;
        background-color: var(--skeleton-color);
      }
      &-content {
        height: 1.5rem;
        width: 100%;
      }
      &-title {
        height: 0.9rem;
        width: 100%;
        max-width: 150px;
      }
    }
  }

  @keyframes skeleton {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
`;

const NewsLettersSkeleton = () => {
  return (
    <StyledNewsLettersSkeleton>
      <section className="news-letter-cards">
        {[1, 2, 3, 4, 5].map((_, i) => (
          <div key={i} className="news-letter-card">
            <div className="news-letter-card-title"></div>
            <div className="news-letter-card-content"></div>
          </div>
        ))}
      </section>
    </StyledNewsLettersSkeleton>
  );
};

export { GeneralStatsSkeleton, NewsLettersSkeleton };
