import { XIcon } from '@primer/octicons-react';
import { CSSProperties } from 'react';
import styled from 'styled-components';

const StyledDialog = styled.section`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 15px;
  z-index: 10;

  .dialog {
    max-width: 600px;
    background-color: var(--bg-color);
    border-radius: 15px;
    padding: 0;
    z-index: 11;
    margin: 25px auto;
    position: relative;
    width: 100%;

    &-floated {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 12;
      height: 100vh;
      border-radius: 0;
      margin: 0;
    }

    &-header {
      background-color: var(--bg-secondary);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      display: flex;
      align-items: center;
      padding: 1rem;
      border-bottom: 1px solid var(--border-color);
      justify-content: space-between;
      user-select: none;

      &-title {
        font-size: 1.1rem;
        font-weight: 600;
      }

      &-close {
        cursor: pointer;

        &:hover {
          color: var(--green-hover);
        }
      }
    }

    &-content {
      background-color: var(--white-color);
      padding: 1rem;
      display: flex;
      flex-direction: column;
      max-height: 650px;
      overflow-y: auto;
      overflow: auto;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      height: 100%;
    }

    &-alert-message {
      font-size: 1rem;
      margin: 0 auto;
      text-align: center;
      padding: 20px;
      width: 100%;

      &-text {
        font-size: 1rem;
        margin: 20px auto;
        max-width: 375px;
      }

      &-normal {
        color: inherit;
      }
    }
  }

  .dialog-alert-message-success {
    color: var(--green);
  }

  .dialog-alert-message-error {
    color: var(--red);
  }

  .dialog-alert-message-warning {
    color: var(--warning);
  }

  /** Dialog Scrollbar */
  .dialog-content::-webkit-scrollbar {
    width: 4px;
  }

  .dialog-content::-webkit-scrollbar-track {
    background: var(--bg-secondary);
  }

  .dialog-content::-webkit-scrollbar-thumb {
    background: var(--border-color);
  }

  .dialog-content::-webkit-scrollbar-thumb:hover {
    background: var(--border-color);
  }

  .dialog-content::-webkit-scrollbar-thumb:active {
    background: var(--border-color);
  }

  .dialog-content::-webkit-scrollbar-thumb:window-inactive {
    background: var(--border-color);
  }

  .dialog-content::-webkit-scrollbar-thumb:vertical {
    border-radius: 15px;
  }

  .dialog-content::-webkit-scrollbar-thumb:horizontal {
    border-radius: 15px;
  }

  .dialog-content::-webkit-scrollbar-thumb:vertical:active {
    border-radius: 15px;
  }

  .dialog-content::-webkit-scrollbar-thumb:horizontal:active {
    border-radius: 15px;
  }

  @media screen and (max-width: 768px) {
    .dialog-overlay {
      padding: 0;
    }

    .dialog {
      max-height: 500px;
      margin: 0 auto;
      bottom: 0;
      position: fixed;
      overflow: auto;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .dialog-header {
      padding: 20px;
    }
  }
`;

interface DialogProps {
  title?: string;
  dialogContent?: string | JSX.Element;
  showDialog?: boolean;
  onClose?: () => void;
  className?: string;
  style?: CSSProperties;
  floated?: boolean;
}

export const Dialog = ({
  title = 'Dialog Title',
  dialogContent = 'Dialog Content',
  showDialog = true,
  className = '',
  onClose = () => {},
  style = {},
  floated = false
}: DialogProps) => {
  return (
    <StyledDialog style={{ display: showDialog ? 'flex' : 'none' }}>
      <section style={style} className={`dialog ${className} ${floated ? 'dialog-floated' : ''}`}>
        <header className="dialog-header">
          <div className="dialog-header-title">{title}</div>
          <div className="dialog-header-close" onClick={onClose}>
            <XIcon size={24} />
          </div>
        </header>
        <section
          style={{
            maxHeight: floated ? '100%' : 650,
            height: floated ? '100%' : 'auto'
          }}
          className="dialog-content"
        >
          {dialogContent}
        </section>
      </section>
    </StyledDialog>
  );
};
