import { ArrowUpRightIcon, PencilIcon } from '@primer/octicons-react';
import { Button } from '@primer/react';
import { Dialog, GroupedButtons, Table } from 'components';
import { CoreContext } from 'contexts';
import { headers } from 'data';
import { Layout } from 'layouts';
import { PageHeader } from 'layouts/header';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { coreService } from 'services';
import { SchoolStatus, SchoolItem } from './models/index.models';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { generateOptionsFromEnum, SelectFeatures } from 'pages/manage/home/components/users';
import { OptionItem, SchoolFeatures } from 'pages/manage/index.models';

export const StyledSchools = styled.section`
  margin: 0 auto;
  padding: 20px;
  width: 100%;
`;

const Schools = () => {
  const navigate = useNavigate();
  const { role, showLineLoader, setShowLineLoader, setShowAlert, curriculums } = useContext(CoreContext);
  const [showDialog, setShowDialog] = useState(false);
  const [schools, setSchools] = useState<SchoolItem[]>([]);
  const [activeTab, setActiveTab] = useState('listed');
  const [fetchingSchools, setFetchingSchools] = useState(true);
  const [editSchool, setEditSchool] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState<OptionItem[]>([]);
  const [currentSchool, setCurrentSchool] = useState<SchoolItem | null>(null);
  useEffect(() => {
    (async () => {
      try {
        document.title = 'Schools - Recess Admin';
        if (role === 'admin') {
          await coreService.getSchools(
            activeTab === 'listed'
              ? {
                  status: SchoolStatus.Active,
                  archived: false
                }
              : { archived: true },
            schools => {
              setSchools(schools ?? []);
              setFetchingSchools(false);
            }
          );
        } else {
          navigate('/');
        }
      } catch (error) {
        if (error instanceof Error) toast.error(error.message);
        setFetchingSchools(false);
      }
    })();
  }, [activeTab, role, navigate]);

  const handleCreateSchool = async (event: any) => {
    event.preventDefault();
    try {
      setShowLineLoader(true);
      const { name, email, phone, code, address, county, logo, curriculum, level, type } = event.target.elements;
      await coreService.createSchool(
        {
          name: name.value,
          email: email.value,
          phone_number: phone.value,
          address: address.value,
          county: county.value,
          code: code.value,
          photo: logo.files[0],
          curriculums: [curriculum.value],
          level: level.value,
          type: type.value,
          features: selectedFeatures.map(feature => feature.value.toString()),
          country: 'KE'
        },
        async () => {
          await coreService.getSchools(
            activeTab === 'listed'
              ? {
                  status: SchoolStatus.Active,
                  archived: false
                }
              : { archived: true },
            schools => {
              setSchools(schools ?? []);
              setFetchingSchools(false);
            }
          );
          event.target.reset();
          setShowLineLoader(false);
          setShowDialog(false);
          toast.success(`School ${name.value} created successfully.`);
        }
      );
    } catch (error: any) {
      setShowLineLoader(false);
      setShowAlert({
        show: true,
        type: 'error',
        title: 'Error',
        message: error.message || 'Sorry, an error occurred while creating school.'
      });
    }
  };

  const handleEditSchool = async (event: any) => {
    event.preventDefault();
    setShowLineLoader(true);

    await coreService
      .updateSchoolFeatures(
        {
          features: selectedFeatures.map(feature => feature.value.toString()),
          school_id: currentSchool?.id ?? ''
        },
        res => {
          if (res.data) {
            setShowLineLoader(false);
            setEditSchool(false);
            setSelectedFeatures([]);
            setSchools(prev =>
              prev.map(school =>
                school.id === currentSchool?.id
                  ? { ...school, features: selectedFeatures.map(feature => feature.value.toString()) }
                  : school
              )
            );
            toast.success(res.message);
          }
        }
      )
      .catch(error => {
        setShowLineLoader(false);
        setShowAlert({
          show: true,
          type: 'error',
          title: 'Error',
          message: error.message || 'Sorry, an error occurred while updatng school feaures.'
        });
      });
  };

  return (
    <Layout
      back={{
        label: 'Back to dashboard',
        link: '/'
      }}
      className="schools"
    >
      <StyledSchools>
        <PageHeader
          onAddClick={() => setShowDialog(true)}
          title="Schools"
          actionButtonLabel="Add School"
          description="All schools on the platform"
        />
        <section className="header-ctas">
          <GroupedButtons
            clickHandler={value => setActiveTab(value)}
            buttons={[
              {
                label: 'Listed'
              },
              {
                label: 'Archived'
              }
            ]}
          />
        </section>
        <Table
          header={[
            {
              label: 'School',
              sort: true
            },
            {
              label: 'Curriculum(s)',
              sort: false
            },
            {
              label: 'County',
              sort: true
            },
            {
              label: 'Date Created',
              sort: true
            },
            {
              label: 'Status',
              width: 80
            },
            {
              label: 'Quick Actions',
              width: 175
            }
          ]}
          data={schools.map(item => (
            <tr key={item.id}>
              <td>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8
                  }}
                >
                  <img
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: 4,
                      objectFit: 'cover',
                      border: '1px solid #ccc',
                      padding: '2px'
                    }}
                    src={item.photo_url}
                    alt={item.name}
                  />
                  <label>{item.name}</label>
                </div>
              </td>
              <td className="capitalize">{item.curriculums[0].id}</td>
              <td style={{ textTransform: 'capitalize' }}>{item.county}</td>
              <td>{item.date_created.short_date}</td>
              <td style={{ textTransform: 'capitalize' }}>
                <select
                  className="form-control table-select"
                  defaultValue={item.status}
                  disabled={showLineLoader || fetchingSchools}
                  onChange={async event => {
                    try {
                      setFetchingSchools(true);
                      await coreService.updateSchool(
                        item.id,
                        {
                          status: Number(event.target.value) as SchoolStatus
                        },
                        res => {
                          setShowAlert({
                            show: true,
                            message: res.message,
                            type: res.data ? 'success' : 'error',
                            title: res.data ? 'Success' : 'Error'
                          });
                          setFetchingSchools(false);
                        }
                      );
                    } catch (error) {
                      setShowAlert({
                        show: true,
                        message: 'Sorry, an error occurred while updating status.',
                        type: 'error',
                        title: 'Error'
                      });
                      setFetchingSchools(false);
                    }
                  }}
                >
                  <option value="1">Active</option>
                  <option value="2">Inactive</option>
                  <option value="3">Suspended</option>
                </select>
              </td>
              <td className="btn-actions">
                <Button
                  variant="danger"
                  size="small"
                  onClick={async () => {
                    try {
                      const isConfirmed = await Swal.fire({
                        title: 'Are you sure?',
                        text: `You are about to delete ${item.name}, this action is irreversible!`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete',
                        confirmButtonColor: '#dc3545',
                        cancelButtonText: 'No, cancel'
                      });
                      if (isConfirmed.isConfirmed) {
                        setShowLineLoader(true);
                        await coreService.deleteSchool(item.id, async res => {
                          try {
                            setFetchingSchools(true);
                            await coreService.getSchools(
                              activeTab === 'listed'
                                ? {
                                    status: SchoolStatus.Active,
                                    archived: false
                                  }
                                : { archived: true },
                              schools => {
                                setSchools(schools ?? []);
                                setFetchingSchools(false);
                                setShowLineLoader(false);
                              }
                            );
                            toast.success(res.message);
                          } catch (error) {
                            setFetchingSchools(false);
                            setShowLineLoader(false);
                            if (error instanceof Error) toast.error(error.message);
                          }
                        });
                      }
                    } catch (error) {
                      setFetchingSchools(false);
                      setShowLineLoader(false);
                      if (error instanceof Error) toast.error(error.message);
                    }
                  }}
                >
                  Delete
                </Button>
                <Button
                  trailingVisual={PencilIcon}
                  size="small"
                  onClick={() => {
                    setEditSchool(true);
                    setCurrentSchool(item);
                    setSelectedFeatures(item.features.map(feature => ({ label: feature, value: feature })));
                  }}
                />
                <Button
                  trailingVisual={ArrowUpRightIcon}
                  size="small"
                  onClick={() => navigate(`/schools/${item.id}`)}
                />
              </td>
            </tr>
          ))}
          ctasConfig={{
            showFilter: true
          }}
          filterConfig={{
            placeholder: 'Filter by',
            options: [
              {
                label: 'All Schools',
                value: SchoolStatus.All.toString()
              },
              {
                label: 'Status',
                value: 'active',
                children: [
                  {
                    label: 'Active',
                    value: SchoolStatus.Active.toString()
                  },
                  {
                    label: 'Inactive',
                    value: SchoolStatus.Inactive.toString()
                  },
                  {
                    label: 'Suspended',
                    value: SchoolStatus.Suspended.toString()
                  }
                ].map(status => {
                  return {
                    label: status.label,
                    value: status.value
                  };
                })
              }
            ]
          }}
          handleFilter={async filter => {
            try {
              setFetchingSchools(true);
              coreService.getSchools(
                {
                  status: Number(filter.value),
                  archived: activeTab === 'listed' ? false : true
                },
                schools => {
                  setSchools(schools ?? []);
                  setFetchingSchools(false);
                }
              );
            } catch (error) {
              setFetchingSchools(false);
              if (error instanceof Error) toast.error(error.message);
            }
          }}
          emptyText={fetchingSchools || showLineLoader ? 'Loading...wait' : 'Sorry, no schools found'}
        />
        <Dialog
          floated={true}
          showDialog={showDialog}
          onClose={() => {
            setShowDialog(false);
            setShowLineLoader(false);
            setFetchingSchools(false);
          }}
          title="Add New School"
          dialogContent={
            <form className="form" onSubmit={handleCreateSchool}>
              <div className="form-group">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  id="name"
                  name="name"
                  className="form-input"
                  type="text"
                  required={true}
                  placeholder="e.g St. Mary's School"
                  autoComplete="organization-title"
                />
              </div>
              <div className="form-group-flex">
                <div className="form-group">
                  <label htmlFor="curriculum" className="form-label">
                    Curriculum
                  </label>
                  <select id="curriculum" name="curriculum" className="form-input" required={true}>
                    <option value="">Select</option>
                    {curriculums.map(county => (
                      <option key={county.slug} value={county.slug}>
                        {county.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="code" className="form-label">
                    Code
                  </label>
                  <input
                    id="code"
                    name="code"
                    className="form-input"
                    type="text"
                    minLength={11}
                    maxLength={11}
                    required={true}
                    placeholder="e.g KNEC/123456/2020"
                  />
                </div>
              </div>
              <div className="form-group-flex">
                <div className="form-group">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    name="email"
                    className="form-input"
                    type="text"
                    required={true}
                    autoComplete="email"
                    placeholder="e.g info@stmary.ac.ke"
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Phone</label>
                  <input
                    id="phone"
                    name="phone"
                    className="form-input"
                    type="tel"
                    required={true}
                    minLength={10}
                    maxLength={10}
                    pattern="[0-9]{10}"
                    autoComplete="tel"
                    placeholder="e.g 0712345678"
                  />
                </div>
              </div>
              <div className="form-group-flex">
                <div className="form-group">
                  <label htmlFor="level" className="form-label">
                    Level
                  </label>
                  <select id="level" name="level" className="form-input" required={true}>
                    <option value="primary">Primary</option>
                    <option value="secondary">Secondary</option>
                    <option value="tertiary">Tertiary</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="type" className="form-label">
                    Type
                  </label>
                  <select id="type" name="type" className="form-input" required={true}>
                    <option value="private">Private</option>
                    <option value="public">Public</option>
                  </select>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="county" className="form-label">
                  County
                </label>
                <select id="county" name="county" className="form-input" required={true}>
                  {headers.counties.map(county => (
                    <option key={county.code} value={county.name}>
                      {county.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="address" className="form-label">
                  Address
                </label>
                <textarea
                  id="address"
                  name="address"
                  className="form-input"
                  required={true}
                  autoComplete="street-address"
                  placeholder="e.g 1234 St. Mary's Road"
                ></textarea>
              </div>

              <div className="form-group">
                <label htmlFor="logo" className="form-label">
                  Logo
                </label>
                <input id="logo" name="logo" className="form-input" type="file" required={true} accept="image/*" />
              </div>
              <SelectFeatures
                onFeatureChange={selectFeatures => setSelectedFeatures(selectFeatures)}
                selectedFeatures={selectedFeatures}
                options={generateOptionsFromEnum(SchoolFeatures)}
                label="select features you want to give to this school"
              />
              <button type="submit" className="button" disabled={showLineLoader} style={{ marginTop: 10 }}>
                Add School
              </button>
            </form>
          }
        />
        <Dialog
          floated={true}
          showDialog={editSchool}
          onClose={() => setEditSchool(false)}
          title="Assign Features"
          dialogContent={
            <form className="form" onSubmit={handleEditSchool}>
              <div className="form-group">
                <SelectFeatures
                  options={generateOptionsFromEnum(SchoolFeatures)}
                  selectedFeatures={selectedFeatures}
                  onFeatureChange={res => setSelectedFeatures(res)}
                  label="Select all features you want to give acccess to this school"
                />
              </div>
              <button type="submit" className="button" disabled={showLineLoader}>
                Save
              </button>
            </form>
          }
        />
      </StyledSchools>
    </Layout>
  );
};

export default Schools;
