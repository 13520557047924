import { Button, CounterLabel } from '@primer/react';
import { TabNavSecondary, Table } from 'components';
import { CoreContext } from 'contexts';
import { IAdmin } from 'models/index.model';
import { EAdminRoleScope, OptionItem, SchoolRoles } from 'pages/manage/index.models';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { coreService } from 'services';
import styled from 'styled-components';

export const generateOptionsFromEnum = (enumObject: { [x: string]: string | number }) => {
  return Object.keys(enumObject)
    .filter(key => isNaN(Number(key)))
    .map(key => ({
      label: key,
      value: enumObject[key]
    }));
};

const StyledSelectFeatures = styled.div`
  label {
    text-transform: capitalize;
    font-size: 14px;
    color: var(--gray-700);
  }
`;

interface SelectFeaturesProps {
  onFeatureChange?: (selectedFeatures: OptionItem[]) => void;
  options: OptionItem[];
  label?: string;
  selectedFeatures?: OptionItem[];
  setSelectedFeatures?: (selectedFeatures: OptionItem[]) => void;
}
export const SelectFeatures = ({
  onFeatureChange,
  options,
  label = '',
  selectedFeatures = [],
  setSelectedFeatures
}: SelectFeaturesProps) => {
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>, feature: OptionItem) => {
    const { checked } = event.target;
    const updatedFeatures = checked
      ? [...selectedFeatures, feature]
      : selectedFeatures.filter(selectedFeature => selectedFeature.value !== feature.value);
    setSelectedFeatures && setSelectedFeatures(updatedFeatures);
    onFeatureChange && onFeatureChange(updatedFeatures);
  };

  return (
    <StyledSelectFeatures>
      <div className="users-features-list">
        <label className="form-label">{label}</label>
        <div className="users-features-list-items flex">
          {options.map(feature => (
            <div key={feature.value}>
              <label style={{ gap: 5, cursor: 'pointer' }} className="flex" htmlFor={feature.label}>
                <input
                  type="checkbox"
                  id={feature.label}
                  name={feature.label}
                  checked={selectedFeatures.some(selectedFeature => selectedFeature.value === feature.value)}
                  onChange={event => handleCheckboxChange(event, feature)}
                />
                <span>{feature.label}</span>
              </label>
            </div>
          ))}
        </div>
      </div>
    </StyledSelectFeatures>
  );
};

interface UserProps {
  data: {
    id: string;
    scope: EAdminRoleScope;
  };
}

const AddUser = ({ data }: UserProps) => {
  const { showLineLoader, setShowLineLoader } = useContext(CoreContext);
  const [features, setFeatures] = useState<OptionItem[]>([]);
  return (
    <form
      className="manage-create-form"
      onSubmit={async (event: any) => {
        event.preventDefault();
        try {
          const { name, email, permission, title } = event.target.elements;
          setShowLineLoader(true);
          const otherProps = {} as { school_id?: string; partner_id?: string };
          data.scope === EAdminRoleScope.Partner ? (otherProps.partner_id = data.id) : (otherProps.school_id = data.id);
          await coreService.addAdminUser(
            {
              ...otherProps,
              email: email.value as string,
              name: name.value as string,
              scope: data.scope,
              permission: Number(permission.value),
              features: features.map(feature => feature.value as SchoolRoles),
              title: title.value as string
            },
            res => toast.success(res.message)
          );
          setShowLineLoader(false);
        } catch (error) {
          if (error instanceof Error) toast.error(error.message);
          setShowLineLoader(false);
        }
      }}
    >
      <div className="manage-type-item-subtitle">Add admins to your account and assign permissions, features</div>
      <div className="form-group-flex">
        <div className="form-group">
          <label htmlFor="name" className="form-label">
            Name
          </label>
          <input
            name="name"
            id="name"
            className="form-input"
            placeholder="For e.g John Doe"
            type="text"
            required={true}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            name="email"
            id="email"
            type="email"
            className="form-input"
            placeholder="For e.g hod@school.com"
            required={true}
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="title" className="form-label">
          Title
        </label>
        <input
          name="title"
          id="title"
          type="text"
          className="form-input"
          placeholder="For e.g Head of School"
          required={true}
        />
      </div>
      <div className="form-group">
        <label htmlFor="permission" className="form-label">
          Select a permission
        </label>
        <select name="permission" id="permission" className="form-input" required={true}>
          {[
            {
              label: 'Read',
              value: 1
            },
            {
              label: 'Write',
              value: 2
            },
            {
              label: 'Read Write',
              value: 3
            }
          ].map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <SelectFeatures
        onFeatureChange={features => setFeatures(features)}
        options={generateOptionsFromEnum(SchoolRoles)}
      />
      <Button disabled={showLineLoader} type="submit">
        {showLineLoader ? 'Please Wait' : 'Add User'}
      </Button>
    </form>
  );
};

const ViewUsers = ({ data }: UserProps) => {
  const [users, setUsers] = useState<IAdmin[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      try {
        setFetching(true);
        await coreService.getAdminUsers(
          {
            school_id: data.id,
            scope: data.scope
          },
          res => setUsers(res.data)
        );
        setFetching(false);
      } catch (error) {
        if (error instanceof Error) toast.error(error.message);
        setFetching(false);
      }
    })();
  }, [data]);

  return (
    <Table
      emptyText={fetching ? 'Fetching users...' : 'No users found'}
      header={[
        {
          label: 'Name'
        },
        {
          label: 'Email'
        },
        {
          label: 'Title'
        },
        {
          label: 'Permission'
        },
        {
          label: 'Features'
        },
        {
          label: 'Actions'
        }
      ]}
      data={users.map(user => (
        <tr key={user.id}>
          <td>{user.name}</td>
          <td>{user.email}</td>
          <td>{user.title}</td>
          <td>{user.permission}</td>
          <td>{user.features.map(item => item.label).join(', ')}</td>
          <td>
            <div className="btn-actions">
              <Button size="small" disabled={fetching}>
                Edit
              </Button>
              <Button size="small" disabled={fetching} variant="danger">
                Remove
              </Button>
            </div>
          </td>
        </tr>
      ))}
    />
  );
};

const StyledUsers = styled.div`
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  border-radius: 15px;
  padding: 15px;
  margin: 20px auto;

  .users {
    &-features-list {
      max-width: 750px;
      margin-bottom: 10px;

      &-items {
        font-size: 0.8rem;
        gap: 5px;
        align-items: center;
        flex-wrap: wrap;
      }
    }
  }
  }
`;

export const Users = () => {
  const { role, user } = useContext(CoreContext);
  const [userView, setUserView] = useState<'add-user' | 'view-users'>('add-user');
  return (
    <StyledUsers>
      <div className="manage-type-item-title">
        <CounterLabel>01</CounterLabel>
        <span>Add Users</span>
      </div>
      <TabNavSecondary
        data={[
          {
            label: 'Add User',
            value: 'add-user'
          },
          {
            label: 'View Users',
            value: 'view-users'
          }
        ]}
        onSelect={value => setUserView(value as 'add-user' | 'view-users')}
      />
      {userView === 'add-user' ? (
        <AddUser
          data={{
            id: role === 'school' ? user?.uid ?? '' : '',
            scope: EAdminRoleScope.School
          }}
        />
      ) : (
        <ViewUsers
          data={{
            id: role === 'school' ? user?.uid ?? '' : '',
            scope: EAdminRoleScope.School
          }}
        />
      )}
    </StyledUsers>
  );
};
