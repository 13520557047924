import { ArrowLeftIcon } from '@primer/octicons-react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import userAvatar from '../../assets/images/user-avatar.png';
import { CoreContext } from '../../contexts';
import { Avatar } from '@primer/react';
import styled from 'styled-components';

const StyledHeader = styled.header`
  .header {
    display: flex;
    align-items: center;
    gap: 8px;
    position: sticky;
    top: 0;
    z-index: 10;
    border-bottom: 1px solid var(--border-color);
    background-color: var(--white-color);
    padding: 10px 20px;
    height: 60px;

    &-user-avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
    }

    &-user {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-left: auto;
      cursor: pointer;

      &-avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
      }

      &-name {
        color: var(--gray-text);
        font-size: 0.8rem;
        font-weight: 600;
        text-decoration: underline;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
          color: var(--hover-color);
        }
      }
    }
  }
`;

const Header = ({
  back
}: {
  back?: {
    label?: string;
    link: string;
  };
}) => {
  const { user, role, profile } = useContext(CoreContext);
  return (
    <StyledHeader>
      <header className="header">
        {back ? (
          <Link
            to={back.link}
            className="header-back"
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              gap: '0.4rem'
            }}
          >
            <ArrowLeftIcon size={24} />
            <span>Back</span>
          </Link>
        ) : null}
        <div className="header-user">
          <Avatar
            className="header-user-avatar"
            src={role === 'school' && profile?.photo_url ? profile?.photo_url : userAvatar}
            alt={role === 'school' && profile?.photo_url ? 'School logo' : 'User avatar'}
          />
          <span className="header-user-name">{user?.email || 'Loading...'}</span>
        </div>
      </header>
    </StyledHeader>
  );
};

export default Header;
