import { ArrowUpRightIcon, DotFillIcon, XIcon } from '@primer/octicons-react';
import { Button, Flash, Tooltip } from '@primer/react';
import { Dialog, GroupedButtons, InputError, Table } from 'components';
import { CoreContext } from 'contexts';
import { Layout } from 'layouts';
import { PageHeader } from 'layouts/header';
import { ClassTeacher, ClassItem, ITeacher } from 'pages/schools/models/index.models';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { coreService, studentsService, teachersService } from 'services';
import { IStudent } from 'services/students/index.models';
import { Results } from './sections';
import './index.css';
import Swal from 'sweetalert2';
import { uniqBy } from 'lodash';
import styled from 'styled-components';

const generateSizes = (count: number) => {
  const sizes = [];
  for (let i = 10; i <= count; i += 10) sizes.push(i);
  return sizes;
};

const StyledSelectSubjects = styled.div`
  .chips {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  .chip {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background: var(--gray-200);
    color: var(--gray-800);
    border-radius: 15px;
    gap: 5px;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      background: var(--gray-300);
    }
  }
`;

const SelectSubjects = ({
  list,
  subjects,
  setSubjects,
  required
}: {
  list: { id: string; name: string }[];
  subjects: { id: string; name: string }[];
  setSubjects: (subjects: { id: string; name: string }[]) => void;
  required?: boolean;
}) => {
  const subjectRef = useRef<HTMLSelectElement>(null);
  return (
    <div className="form-group">
      <label className="form-label">Subjects</label>
      {subjects.length ? (
        <StyledSelectSubjects>
          <div className="chips">
            {subjects.map(subject => (
              <span
                className="chip"
                onClick={() => {
                  const newSubjects = uniqBy(
                    subjects.filter(item => item.id !== subject.id),
                    'id'
                  );
                  setSubjects(newSubjects);
                }}
              >
                {subject.name}
                <XIcon />
              </span>
            ))}
          </div>
        </StyledSelectSubjects>
      ) : null}
      <select
        ref={subjectRef}
        name="subject"
        className="form-input"
        onChange={event => {
          const { value } = event.target;
          if (value === 'all') {
            setSubjects(list.map(item => ({ id: item.id, name: item.name.toLocaleLowerCase() })));
          } else {
            if (!subjects.map(c => c.id).includes(value.toLocaleLowerCase())) {
              setSubjects([...subjects, { id: value.toLocaleLowerCase(), name: value }]);
            }
          }
        }}
        required={required}
      >
        <option value="">Select</option>
        <option value="all">All</option>
        {list
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((subject, index) => (
            <option key={index} value={subject.id}>
              {subject.name}
            </option>
          ))}
      </select>
    </div>
  );
};

const SchoolClass = () => {
  const params = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();

  const { setShowAlert, showLineLoader, setShowLineLoader, user, role } = useContext(CoreContext);
  const [schoolTeachers, setSchoolTeachers] = useState<ITeacher[]>([]);

  const [showDialog, setShowDialog] = useState(false);
  const [activeTab, setActiveTab] = useState('students');
  const [showMenuList, setShowMenuList] = useState(false);
  const [classData, setClassData] = useState<ClassItem | null>(null);
  const [teachers, setTeachers] = useState<ITeacher[]>([]);
  const [subjects, setSubjects] = useState<{ id: string; name: string }[]>([]);
  const [addParent, setAddParent] = useState(false);
  const [editStudent, setEditStudent] = useState(false);
  const [editTeacher, setEditTeacher] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState<ITeacher | null>(null);
  const [primaryParentEmail, setPrimaryParentEmail] = useState('');
  const [secondaryParentEmail, setSecondaryParentEmail] = useState('');
  const [parentEmailError, setParentEmailError] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<IStudent | null>(null);
  const [studentList, seStudentList] = useState<IStudent[]>([]);
  const [teacherUpdated, setTeacherUpdated] = useState(false);
  const [studentUpdated, setStudentUpdated] = useState(false);
  const [addNewTeacher, setAddNewTeacher] = useState(true);
  const [bulkUpload, setBulkUpload] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    count: 0,
    pages: 0,
    size: 0
  });
  const [paginate, setPaginate] = useState({
    page: 1,
    size: 10
  });
  const [fetching, setFetching] = useState(false);
  const [addingTeacher, setAddingTeacher] = useState(false);
  const [uploadingParents, setUploadingParents] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
  const [allSelected, setAllSelected] = useState(false);
  const [selectMenuOptionsView, setSelectMenuOptionsView] = useState<string>('move');
  const [showSelectMenuOptionsDialog, setShowSelectMenuOptionsDialog] = useState(false);
  const [schoolClasses, setSchoolClasses] = useState<ClassItem[]>([]);
  useEffect(() => {
    (async () => {
      const school_id = role === 'admin' ? location.pathname.split('/')[2] : user?.uid;
      try {
        if (params.id && location && user && school_id) {
          setShowLineLoader(true);
          await coreService.getClassById(school_id, params.id, res => {
            setShowLineLoader(false);
            setClassData(res);
          });
        }
      } catch (error) {
        setShowLineLoader(false);
        role === 'admin' ? navigate(`/school/${school_id}`) : navigate(`/`);
      }
    })();
  }, [params, location, navigate, setShowLineLoader, user, role]);

  useEffect(() => {
    (async () => {
      try {
        if (classData) await coreService.getClassesBySchoolId(classData.school_id, res => setSchoolClasses(res));
      } catch (error) {
        setSchoolClasses([]);
      }
    })();
  }, [classData]);

  useEffect(() => {
    (async () => {
      try {
        if (classData && classData.id) {
          switch (activeTab) {
            case 'students':
              setFetching(true);
              await studentsService.getClassStudents(classData?.school_id ?? '', classData?.id ?? '', paginate, res => {
                seStudentList(res.data);
                setPagination(res.pagination);
                setShowLineLoader(false);
                setStudentUpdated(false);
                setFetching(false);
              });
              break;
            case 'teachers':
              setFetching(true);
              await teachersService.getTeachers(
                {
                  school_id: classData?.school_id ?? '',
                  class_id: classData?.id ?? ''
                },
                res => {
                  setTeachers(res);
                  setShowLineLoader(false);
                  setTeacherUpdated(false);
                  setFetching(false);
                }
              );
              teachersService.getTeachers(
                {
                  school_id: classData?.school_id ?? ''
                },
                res => {
                  setSchoolTeachers(res);
                  setShowLineLoader(false);
                }
              );
              break;
            default:
              setShowLineLoader(false);
              setTeacherUpdated(false);
              break;
          }
        }
      } catch (error) {
        if (error instanceof Error) toast.error(error.message);
        setShowLineLoader(false);
        setFetching(false);
      }
    })();
  }, [activeTab, classData, setShowLineLoader, teacherUpdated, studentUpdated, paginate]);

  const handleAddTeacher = async (event: any) => {
    event.preventDefault();
    try {
      const { teacher_first_name, teacher_last_name, teacher_email, class_teacher } = event.target.elements;
      setAddingTeacher(true);
      if (classData) {
        const teacherPayload = {
          email: addNewTeacher ? teacher_email.value : selectedTeacher?.email ?? '',
          first_name: addNewTeacher ? teacher_first_name.value : selectedTeacher?.first_name ?? '',
          last_name: addNewTeacher ? teacher_last_name.value : selectedTeacher?.last_name ?? '',
          class_id: classData.id,
          class_teacher: class_teacher.value === 'yes',
          subjects: subjects.map(item => item.id).filter(item => item !== 'all' && item !== '')
        };
        await teachersService.createTeacher(classData.school_id, teacherPayload, () => {
          setShowDialog(false);
          setShowAlert({
            show: true,
            type: 'success',
            title: 'Success',
            message: 'Teacher added successfully.'
          });
        });
        await teachersService.getTeachers(
          {
            school_id: classData?.school_id ?? '',
            class_id: classData?.id ?? ''
          },
          res => {
            setTeachers(res);
            setTeacherUpdated(false);
            setAddingTeacher(false);
          }
        );
      }
    } catch (error) {
      if (error instanceof Error) {
        setShowAlert({
          show: true,
          type: 'error',
          title: 'Error',
          message: error.message
        });
      }
      setAddingTeacher(false);
    }
  };

  const handleEditTeacher = async (event: any) => {
    event.preventDefault();
    try {
      setShowLineLoader(true);
      const { class_teacher } = event.target.elements;

      const teacherPayload = {
        class_id: classData?.id ?? '',
        class_teacher: class_teacher.value === 'yes' ? ClassTeacher.Yes : ClassTeacher.No,
        subjects: subjects.map(item => item.id).filter(item => item !== 'all' && item !== '')
      };
      await teachersService.updateTeacher(classData?.school_id ?? '', selectedTeacher?.id ?? '', teacherPayload, () => {
        setShowLineLoader(false);
        setEditTeacher(false);
        setActiveTab('teachers');
        setTeacherUpdated(true);
        setShowAlert({
          show: true,
          type: 'success',
          title: 'Success',
          message: 'Teacher updated successfully.'
        });
      });
    } catch (error) {
      if (error instanceof Error) {
        setShowAlert({
          show: true,
          type: 'error',
          title: 'Error',
          message: error.message
        });
      }
      setShowLineLoader(false);
    }
  };

  const handleAddStudent = async (event: any) => {
    event.preventDefault();
    try {
      if (classData) {
        setShowLineLoader(true);
        const {
          student_first_name,
          student_last_name,
          student_gender,
          student_admission_no,
          student_admission_date,
          parent_name,
          parent_email,
          parent_2_email,
          parent_2_name
        } = event.target.elements;
        const student = {
          first_name: student_first_name.value,
          last_name: student_last_name.value,
          admission_no: student_admission_no.value,
          admission_date: new Date(student_admission_date.value).toISOString(),
          gender: student_gender.value,
          parents: [
            {
              name: parent_name.value,
              email: parent_email.value
            }
          ]
        };
        if (parent_2_name && parent_2_email) {
          student.parents.push({
            name: parent_2_name.value,
            email: parent_2_email.value
          });
        }
        await studentsService.createStudent(
          {
            class_id: classData.id,
            school_id: classData.school_id,
            student
          },
          res => {
            toast.success(`Student ${res.first_name} ${res.last_name} added successfully`);
            setShowDialog(false);
            setShowMenuList(false);
          }
        );
        await studentsService.getClassStudents(classData.school_id, classData.id, paginate, res => {
          seStudentList(res.data);
          setPagination(res.pagination);
          setShowLineLoader(false);
          setStudentUpdated(false);
        });
      } else {
        setShowLineLoader(true);
      }
    } catch (error) {
      if (error instanceof Error) toast.error(error.message);
      setShowLineLoader(false);
    }
  };

  const handleBulkUpload = async (event: any) => {
    event.preventDefault();
    const { csv } = event.target.elements;
    try {
      switch (activeTab) {
        case 'students':
          setUploadingParents(true);
          if (csv.files[0].type !== 'text/csv') {
            setShowAlert({
              show: true,
              type: 'error',
              title: 'Error',
              message: 'Please upload a csv file.'
            });
          } else {
          }
          break;
      }
    } catch (error) {
      if (error instanceof Error) toast.error(error.message);
      setShowLineLoader(false);
      setUploadingParents(false);
    }
  };

  return (
    <Layout
      back={{
        label: classData?.name,
        link: role === 'admin' ? `/schools/${classData?.school_id}` : '/classes/'
      }}
      className="class"
    >
      <section className="class-content">
        <PageHeader
          showActionButton={activeTab !== 'results'}
          onAddClick={() => {
            if (activeTab === 'teachers') {
              setShowDialog(true);
              setShowMenuList(false);
              setSubjects([]);
            }

            if (activeTab === 'students') {
              setShowDialog(false);
              setShowMenuList(true);
            }
          }}
          actionButtonLabel={activeTab === 'students' ? '' : 'Add Teacher'}
          showMenuList={showMenuList && !showDialog}
          title={classData?.name ?? ''}
          description="Add teachers, students to class"
          loading={showLineLoader}
        />
        <section className="class-ctas">
          <GroupedButtons
            className="class-grouped-buttons"
            clickHandler={value => {
              setActiveTab(value);
              setShowMenuList(false);
            }}
            buttons={[
              {
                label: 'Students'
              },
              {
                label: 'Teachers'
              },
              {
                label: 'Results'
              }
            ]}
          />
        </section>
        {activeTab === 'teachers' && (
          <Table
            itemsLabel="Teachers"
            header={[
              {
                label: 'Name',
                width: 50
              },
              {
                label: 'Email',
                width: 50
              },
              {
                label: 'Subjects',
                width: 100
              },
              {
                label: 'Date Created',
                width: 50
              },
              {
                label: 'Quick Actions',
                width: 100
              }
            ]}
            data={teachers.map(item => (
              <tr key={item.id}>
                <td className="tb-teacher-name">
                  {item.class_teacher.includes(classData?.id ?? '') ? (
                    <Tooltip aria-label="Class Teacher" className="tb-parent-name">
                      <span>{item.first_name}</span> <span>{item.last_name}</span>
                      <DotFillIcon className="tb-dot-active" />
                    </Tooltip>
                  ) : (
                    <>
                      <span>{item.first_name}</span> <span>{item.last_name}</span>
                    </>
                  )}
                </td>
                <td>{item.email}</td>
                <td
                  style={{
                    textTransform: 'capitalize'
                  }}
                >
                  {item.classes?.find(item => item.id === classData?.id)?.subjects.join(', ')}
                </td>
                <td>{item.date_created.short_date}</td>
                <td>
                  <div className="btn-actions">
                    <Button
                      size="small"
                      onClick={() => {
                        const classSubjects = item.classes?.find(item => item.id === classData?.id)?.subjects ?? [];
                        setEditTeacher(true);
                        setSelectedTeacher(item);
                        setSubjects(
                          classSubjects.map(subject => ({
                            id: subject.toLocaleLowerCase(),
                            name: subject
                          }))
                        );
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      aria-label="Remove from class"
                      size="small"
                      onClick={async () => {
                        try {
                          setShowLineLoader(true);
                          await teachersService.removeFromClass(item.id, classData?.id ?? '', () => {
                            setSelectedTeacher(null);
                            setShowLineLoader(false);
                          });
                          await teachersService.getTeachers(
                            {
                              school_id: item.school_id,
                              class_id: classData?.id ?? ''
                            },
                            res => {
                              setTeachers(res);
                              setShowAlert({
                                show: true,
                                type: 'success',
                                title: 'Success',
                                message: 'Teacher removed from class successfully'
                              });
                            }
                          );
                        } catch (error) {
                          if (error instanceof Error) toast.error(error.message);
                          setShowLineLoader(false);
                        }
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
            filterConfig={{
              placeholder: 'Filter by',
              options: [
                {
                  label: 'Active',
                  value: 'active'
                },
                {
                  label: 'Archived',
                  value: 'archived'
                },
                {
                  label: 'All',
                  value: 'all'
                }
              ]
            }}
            handleFilter={filter => {
              switch (filter.value) {
                case 'active':
                  break;
                case 'archived':
                  break;
                case 'all':
              }
            }}
            emptyText={showLineLoader || fetching ? 'Loading...' : 'No teachers found'}
          />
        )}
        {activeTab === 'students' && (
          <>
            <Table
              totalItems={pagination.count}
              itemsLabel="Students"
              header={[
                {
                  label: 'Admission No',
                  showSelect: true,
                  width: 30
                },
                {
                  label: 'Admission Date',
                  width: 50
                },
                {
                  label: 'Name',
                  width: 50
                },
                {
                  label: 'Gender'
                },
                {
                  label: 'Parents'
                },

                {
                  label: 'Quick Actions',
                  width: 100
                }
              ]}
              selectMenuOptions={[
                {
                  label: 'Move Students',
                  value: 'move'
                }
              ]}
              selectedCount={selectedStudents.length}
              onSelectAll={selected => setSelectedStudents(selected ? studentList.map(item => item.id) : [])}
              onSelectedMenuOption={value => {
                switch (value) {
                  case 'move':
                    setShowSelectMenuOptionsDialog(true);
                    setSelectMenuOptionsView('move');
                    break;
                }
              }}
              data={studentList.map(item => (
                <tr key={item.id}>
                  <td>
                    <label className="flex" style={{ gap: 3, cursor: 'pointer' }}>
                      <input
                        type="checkbox"
                        checked={selectedStudents.includes(item.id)}
                        onChange={event => {
                          if (event.target.checked) {
                            setSelectedStudents([...selectedStudents, item.id]);
                          } else {
                            allSelected && setAllSelected(false);
                            setSelectedStudents(selectedStudents.filter(student => student !== item.id));
                          }
                        }}
                      />
                      <span>{item.admission_no}</span>
                    </label>
                  </td>
                  <td>{item.admission_date.short_date}</td>
                  <td>
                    <span>{item.first_name} </span>
                    <span>{item.last_name} </span>
                  </td>
                  <td>
                    <div className="tb-parent-name">{item.gender}</div>
                  </td>
                  <td>
                    <div className="tb-parent-name">{item.parents.length}</div>
                  </td>
                  <td>
                    <div className="btn-actions">
                      <Button
                        size="small"
                        onClick={() => {
                          setSelectedStudent(item);
                          setEditStudent(true);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        size="small"
                        onClick={async () => {
                          try {
                            const deleteRes = await Swal.fire({
                              title: 'Are you sure?',
                              text: 'You will not, be able to recover this student',
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonColor: '#dc3545',
                              cancelButtonColor: '#6a737d',
                              confirmButtonText: 'Yes, delete',
                              cancelButtonText: 'No, cancel'
                            });

                            if (deleteRes.isConfirmed) {
                              setShowLineLoader(true);
                              await studentsService.deleteStudent(item.id, async res => {
                                try {
                                  setShowLineLoader(false);
                                  setStudentUpdated(true);
                                  await studentsService.getClassStudents(
                                    classData?.school_id ?? '',
                                    classData?.id ?? '',
                                    paginate,
                                    res => {
                                      seStudentList(res.data);
                                      setPagination(res.pagination);
                                    }
                                  );
                                  toast.success(res.message);
                                } catch (error) {
                                  setShowLineLoader(false);
                                  if (error instanceof Error) toast.error(error.message);
                                }
                              });
                            }
                          } catch (error) {
                            setShowLineLoader(false);
                            if (error instanceof Error) toast.error(error.message);
                          }
                        }}
                      >
                        Delete
                      </Button>
                      <Button size="small" onClick={() => navigate(`/students/${item.id}`)}>
                        <ArrowUpRightIcon />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
              emptyText={
                showLineLoader || fetching
                  ? 'Loading...'
                  : 'Waiting for parents to onboard their children. No students found'
              }
              pagination={{
                show: true,
                options: {
                  sizes: generateSizes(pagination.count),
                  pages: pagination.pages,
                  currentPage: paginate.page,
                  handlePageChange: async data => {
                    setPaginate(data);
                  }
                }
              }}
              filterConfig={{
                placeholder: 'Filter by',
                options: [
                  {
                    label: 'Active',
                    value: 'active'
                  },
                  {
                    label: 'Archived',
                    value: 'archived'
                  },
                  {
                    label: 'All',
                    value: 'all'
                  }
                ]
              }}
            />
          </>
        )}
        {activeTab === 'results' && (
          <Results
            subjects={classData?.subjects ?? []}
            payload={{
              class_id: classData?.id ?? '',
              school_id: classData?.school_id ?? '',
              class_name: classData?.name ?? ''
            }}
          />
        )}
        <Dialog
          floated={true}
          showDialog={showDialog}
          onClose={() => {
            setShowDialog(false);
            setBulkUpload(false);
            setShowLineLoader(false);
            setShowMenuList(false);
          }}
          title={activeTab === 'students' ? 'Add Parents' : 'Add Teacher'}
          dialogContent={
            activeTab === 'students' || activeTab === 'results' ? (
              <section>
                {bulkUpload ? (
                  <section className="bulk-upload">
                    <div className="bulk-upload-info">
                      Make sure to use the{' '}
                      <u>
                        <b>
                          <Tooltip aria-label="Download CSV Template" direction="s">
                            <a
                              href={
                                activeTab === 'students'
                                  ? 'https://recess-dev-admin.web.app/docs/recess-parents-template.csv'
                                  : 'https://recess-dev-admin.web.app/docs/recess-resultslate.csv'
                              }
                              download="recess-parents-template"
                            >
                              csv template
                            </a>
                          </Tooltip>
                        </b>
                      </u>{' '}
                      to bulk upload {activeTab === 'students' ? 'parents' : 'results'}
                    </div>
                    <form className="form" onSubmit={handleBulkUpload}>
                      <div className="form-group">
                        <label htmlFor="csv" className="form-label">
                          Select CSV File
                        </label>
                        <input
                          id="csv"
                          name="csv"
                          accept=".csv"
                          className="form-input"
                          type="file"
                          onChange={() => {}}
                          required={true}
                        />
                      </div>
                      <button type="submit" className="button btn-md" disabled={uploadingParents}>
                        {uploadingParents ? 'Uploading...' : 'Upload'}
                      </button>
                    </form>
                  </section>
                ) : (
                  <form className="form-container form" onSubmit={handleAddStudent} autoComplete="off">
                    <div className="form-group-flex">
                      <div className="form-group">
                        <label htmlFor="student_first_name" className="form-label">
                          First Name
                        </label>
                        <input
                          name="student_first_name"
                          id="student_first_name"
                          className="form-input"
                          type="text"
                          required={true}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="student_last_name" className="form-label">
                          Last Name
                        </label>
                        <input
                          name="student_last_name"
                          id="student_last_name"
                          className="form-input"
                          type="text"
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="student_gender" className="form-label">
                        Gender
                      </label>
                      <select name="student_gender" id="student_gender" required={true} className="form-input">
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="O">Other</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="student_admission_no" className="form-label">
                        Admission No
                      </label>
                      <input
                        name="student_admission_no"
                        id="student_admission_no"
                        className="form-input"
                        type="text"
                        required={true}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="student_admission_date" className="form-label">
                        Admission Date
                      </label>
                      <input
                        name="student_admission_date"
                        id="student_admission_date"
                        className="form-input"
                        type="date"
                        max={new Date().toISOString().split('T')[0]}
                        required={true}
                      />
                    </div>
                    <div className="form-group-flex">
                      <div className="form-group">
                        <label htmlFor="parent_name" className="form-label">
                          Parent Name
                        </label>
                        <input name="parent_name" id="parent_name" className="form-input" type="text" required={true} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="parent_email" className="form-label">
                          Parent Email / Phone
                        </label>
                        <input
                          name="parent_email"
                          id="parent_email"
                          className="form-input"
                          type="email"
                          onChange={e => setPrimaryParentEmail(e.target.value)}
                          required={true}
                        />
                        <InputError
                          show={!coreService.validateEmail(primaryParentEmail)}
                          message="Please enter a valid email address!"
                        />
                      </div>
                    </div>
                    <div className="form-add-cta" onClick={() => setAddParent(prev => !prev)}>
                      {addParent ? 'Remove Second Parent' : 'Add Second Parent'}
                    </div>
                    {addParent ? (
                      <div className="form-group-flex">
                        <div className="form-group">
                          <label htmlFor="parent_2_name" className="form-label">
                            Parent 2 Name
                          </label>
                          <input
                            name="parent_2_name"
                            id="parent_2_name"
                            className="form-input"
                            type="text"
                            required={true}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="parent_2_email" className="form-label">
                            Parent 2 Email
                          </label>
                          <input
                            name="parent_2_email"
                            id="parent_2_email"
                            className="form-input"
                            type="email"
                            onChange={e => {
                              setParentEmailError(e.target.value.length > 3 && e.target.value === primaryParentEmail);
                              setSecondaryParentEmail(e.target.value);
                            }}
                            required={true}
                          />
                          <InputError
                            show={!coreService.validateEmail(secondaryParentEmail)}
                            message="Please enter a valid email address!"
                          />
                          <InputError show={parentEmailError} message="Please use a different email address!" />
                        </div>
                      </div>
                    ) : null}
                    <button
                      aria-label="Add Student Button"
                      type="submit"
                      className="button"
                      disabled={
                        showLineLoader ||
                        parentEmailError ||
                        !coreService.validateEmail(primaryParentEmail) ||
                        !coreService.validateEmail(secondaryParentEmail)
                      }
                    >
                      Add Student
                    </button>
                  </form>
                )}
              </section>
            ) : (
              <section>
                <form className="form" onSubmit={handleAddTeacher}>
                  <div
                    style={{
                      border: '1px solid var(--green)',
                      padding: '10px 15px',
                      borderRadius: '5px',
                      display: schoolTeachers.length ? 'block' : 'none'
                    }}
                    className="form-group"
                  >
                    <div
                      style={{
                        fontSize: '13px'
                      }}
                    >
                      Click below to select a create option below
                    </div>
                    <div
                      style={{ marginBottom: 0 }}
                      className="form-add-cta"
                      onClick={() => setAddNewTeacher(prev => !prev)}
                    >
                      {addNewTeacher ? 'Select Teacher' : 'Add New Teacher'}
                    </div>
                  </div>
                  {schoolTeachers.length > 0 && !addNewTeacher && (
                    <div className="form-group">
                      <label className="form-label" htmlFor="teacher">
                        Select Teacher
                      </label>
                      <select
                        name="teacher"
                        className="form-input"
                        required={true}
                        onChange={event => {
                          const { value } = event.target;
                          const teacher = schoolTeachers.find(item => item.id === value);
                          if (teacher) {
                            setAddNewTeacher(false);
                            setSelectedTeacher(teacher);
                            setSubjects(
                              teacher.classes
                                ?.find(item => item.id === classData?.id)
                                ?.subjects.map(item => ({
                                  id: item.toLocaleLowerCase(),
                                  name: item
                                })) ?? []
                            );
                          } else {
                            setAddNewTeacher(true);
                            setSelectedTeacher(null);
                            setSubjects([]);
                          }
                        }}
                      >
                        <option value="">Select</option>
                        {schoolTeachers.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.first_name} {item.last_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {addNewTeacher && (
                    <>
                      <div className="form-group-flex">
                        <div className="form-group">
                          <label className="form-label">First Name</label>
                          <input name="teacher_first_name" className="form-input" type="text" required={true} />
                        </div>
                        <div className="form-group">
                          <label className="form-label">Last Name</label>
                          <input name="teacher_last_name" className="form-input" type="text" required={true} />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-label">School Email</label>
                        <input name="teacher_email" className="form-input" type="email" />
                      </div>
                    </>
                  )}
                  <div className="form-group">
                    <label htmlFor="class_teacher" className="form-label">
                      Is Class Teacher
                    </label>
                    <select name="class_teacher" className="form-input" defaultValue="no" required={true}>
                      {['No', 'Yes'].map(item => (
                        <option key={item.toLocaleLowerCase()} value={item.toLocaleLowerCase()}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <SelectSubjects
                    list={classData?.subjects ?? []}
                    subjects={subjects}
                    setSubjects={setSubjects}
                    required={true}
                  />
                  <button type="submit" className="button" disabled={addingTeacher}>
                    Add Teacher
                  </button>
                </form>
              </section>
            )
          }
        />
        {editStudent || editTeacher ? (
          <Dialog
            floated={true}
            title={editStudent ? 'Edit Student' : 'Edit Teacher'}
            onClose={() => {
              setEditStudent(false);
              setEditTeacher(false);
            }}
            showDialog={true}
            dialogContent={
              <section>
                {editStudent ? (
                  <form
                    className="form"
                    onSubmit={async (event: any) => {
                      event.preventDefault();
                      try {
                        setShowLineLoader(true);
                        const { student_first_name, student_last_name, student_admission_number, gender } =
                          event.target;
                        const studentPayload = {
                          first_name: student_first_name?.value,
                          last_name: student_last_name?.value,
                          gender: gender?.value,
                          admission_no: student_admission_number?.value
                        };
                        await studentsService.updateStudent(selectedStudent?.id ?? '', studentPayload, () => {
                          setStudentUpdated(true);
                          setShowLineLoader(false);
                          setEditStudent(false);
                          setActiveTab('students');
                          setShowAlert({
                            show: true,
                            title: 'Success',
                            message: 'Student updated successfully.',
                            type: 'success'
                          });
                        });
                      } catch (error) {
                        setShowLineLoader(false);
                        if (error instanceof Error) toast.error(error.message);
                      }
                    }}
                    autoComplete="off"
                  >
                    <div className="form-group-flex">
                      <div className="form-group">
                        <label className="form-label">First Name</label>
                        <input
                          name="student_first_name"
                          className="form-input"
                          type="text"
                          required={true}
                          defaultValue={selectedStudent?.first_name}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Last Name</label>
                        <input
                          name="student_last_name"
                          className="form-input"
                          type="text"
                          required={true}
                          defaultValue={selectedStudent?.last_name}
                        />
                      </div>
                    </div>
                    {selectedStudent &&
                      selectedStudent?.parents?.length > 0 &&
                      selectedStudent?.parents.map((item, index) => (
                        <div style={{ alignItems: 'center' }} className="form-group-flex" key={index}>
                          <div className="form-group">
                            <label className="form-label" htmlFor={`parent_name_${index}`}>
                              {index === 0 ? 'Parent Name' : `Second Parent Name`}
                            </label>
                            <input
                              name={`parent_name_${index}`}
                              className="form-input"
                              type="text"
                              disabled={true}
                              defaultValue={item.name}
                            />
                          </div>
                          <div className="form-group">
                            <label className="form-label" htmlFor={`parent_username${index}`}>
                              Parent Username
                            </label>
                            <input
                              name={`parent_username${index}`}
                              className="form-input"
                              type="email"
                              disabled={true}
                              defaultValue={item.email ?? item.phone_number}
                            />
                          </div>
                        </div>
                      ))}
                    <div className="form-group">
                      <label htmlFor="gender" className="form-label">
                        Gender
                      </label>
                      <select name="gender" className="form-input" defaultValue={selectedStudent?.gender ?? 'M'}>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="O">Other</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="student_admission_number" className="form-label">
                        Admission Number
                      </label>
                      <input
                        name="student_admission_number"
                        className="form-input"
                        type="text"
                        required={true}
                        defaultValue={selectedStudent?.admission_no}
                      />
                    </div>
                    <button type="submit" className="button" disabled={showLineLoader || parentEmailError}>
                      Save Changes
                    </button>
                  </form>
                ) : (
                  <form className="form" onSubmit={handleEditTeacher}>
                    <div className="form-group-flex">
                      <div className="form-group">
                        <label htmlFor="teacher_first_name" className="form-label">
                          First Name
                        </label>
                        <input
                          id="teacher_first_name"
                          name="teacher_first_name"
                          className="form-input"
                          type="text"
                          required={true}
                          disabled={true}
                          defaultValue={selectedTeacher?.first_name}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="teacher_last_name" className="form-label">
                          Last Name
                        </label>
                        <input
                          id="teacher_last_name"
                          name="teacher_last_name"
                          className="form-input"
                          type="text"
                          required={true}
                          disabled={true}
                          defaultValue={selectedTeacher?.last_name}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="class_teacher" className="form-label">
                        Is Class Teacher
                      </label>
                      <select
                        defaultValue={selectedTeacher?.class_teacher.includes(classData?.id ?? '') ? 'yes' : 'no'}
                        name="class_teacher"
                        className="form-input"
                        required={true}
                      >
                        {['No', 'Yes'].map(item => (
                          <option key={item.toLocaleLowerCase()} value={item.toLocaleLowerCase()}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                    <SelectSubjects list={classData?.subjects ?? []} subjects={subjects} setSubjects={setSubjects} />
                    <button type="submit" className="button" disabled={showLineLoader}>
                      Save Changes
                    </button>
                  </form>
                )}
              </section>
            }
          />
        ) : null}
        {showSelectMenuOptionsDialog ? (
          <Dialog
            title="Move Students"
            onClose={() => {
              setShowSelectMenuOptionsDialog(false);
            }}
            showDialog={true}
            dialogContent={
              selectMenuOptionsView === 'move' ? (
                <section>
                  <form
                    className="form"
                    onSubmit={async (event: any) => {
                      event.preventDefault();
                      try {
                        if (classData && event.target.class.value) {
                          setShowLineLoader(true);
                          await studentsService.moveStudents(
                            {
                              school_id: classData.school_id,
                              class_id: classData.id,
                              next_class_id: event.target.class.value,
                              students: selectedStudents
                            },
                            res => toast.success(res)
                          );
                          await studentsService.getClassStudents(classData.school_id, classData.id, paginate, res => {
                            seStudentList(res.data);
                            setPagination(res.pagination);
                            setShowLineLoader(false);
                            setStudentUpdated(false);
                            setShowSelectMenuOptionsDialog(false);
                            setSelectedStudents([]);
                          });
                          event.target.reset();
                        }
                      } catch (error) {
                        if (error instanceof Error) toast.error(error.message);
                        setShowLineLoader(false);
                      }
                    }}
                  >
                    <div className="form-group">
                      <Flash
                        variant="warning"
                        style={{
                          padding: '8px 10px',
                          fontSize: 13,
                          marginBottom: 15
                        }}
                      >
                        Please make sure you have the right class selected. You are about to move{' '}
                        <b>{selectedStudents.length}</b> student(s).
                      </Flash>
                      <label htmlFor="class" className="form-label">
                        Select Next Class
                      </label>
                      <select name="class" className="form-input" required={true}>
                        {schoolClasses.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <div className="btns">
                        <button disabled={showLineLoader} type="submit" className="button btn-md">
                          Move
                        </button>
                        <button
                          type="button"
                          disabled={showLineLoader}
                          className="button btn-delete btn-md"
                          onClick={() => {
                            setShowSelectMenuOptionsDialog(false);
                            setSelectedStudents([]);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </section>
              ) : (
                <></>
              )
            }
          />
        ) : null}
      </section>
    </Layout>
  );
};

export { SchoolClass, SelectSubjects };
