import { Rings } from 'react-loader-spinner';
import styled from 'styled-components';

const StyledLineLoader = styled.section`
  .loader {
    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 15px;
      z-index: 20;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.45;
    }
    &-line {
      background-color: var(--loader-line-color);
      overflow: hidden;
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: flex-start;
      z-index: 1000;
      width: 100%;
      &-container {
        height: var(--loader-line-size);
        width: 100%;
        background: var(--loader-line-back-color);
        &:before {
          content: '';
          display: block;
          background-color: var(--loader-line-color);
          height: var(--loader-line-size);
          width: 0;
          animation: getWidth var(--loader-line-time) ease-in infinite;
        }
      }
    }
    &-page {
      display: none;
      flex-direction: column;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 15px;
      z-index: 10;
      justify-content: center;
      align-items: center;
      opacity: 0.4;
    }
  }
  @keyframes getWidth {
    100% {
      width: 100%;
    }
  }
`;

const LineLoader = ({ show = true, size = '2px', color = '#00bcd4', speed = 1, hideShadow = false }) => {
  return show ? (
    <StyledLineLoader>
      <section
        style={{
          backgroundColor: hideShadow ? 'transparent' : 'rgba(0, 0, 0, 0.5)'
        }}
        className="loader-overlay"
      >
        <div className="loader-line">
          <div className="loader-line-container"></div>
        </div>
      </section>
    </StyledLineLoader>
  ) : null;
};

const PageLoader = ({ show = true }) => {
  return (
    <section style={show ? { display: 'flex' } : {}} className="loader-page">
      <Rings color="#ddd" ariaLabel="loading-indicator" />
    </section>
  );
};

export { LineLoader, PageLoader };
