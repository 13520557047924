import { ArrowLeftIcon } from '@primer/octicons-react';
import { Button, Pagination, TabNav } from '@primer/react';
import { NoData, Search } from 'components';
import { NewsLettersSkeleton } from 'components/skeletons';
import { CoreContext } from 'contexts';
import { Layout } from 'layouts';
import { PaginationObject } from 'models/index.model';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { coreService, newsLettersService } from 'services';
import { ENewsLetterStatus, INewsLetter } from 'services/newsletters/index.models';
import { NewsLetterCard } from './components';
import './index.css';
import ReactQuill from 'react-quill';

const TABS = [
  {
    label: 'All',
    id: ENewsLetterStatus.All
  },
  {
    label: 'Sent',
    id: ENewsLetterStatus.Active
  },
  {
    label: 'Draft',
    id: ENewsLetterStatus.Draft
  }
];

const NewsLetters = () => {
  const navigate = useNavigate();
  const { setShowLineLoader, setShowAlert, user, role } = useContext(CoreContext);
  const [fetching, setFetching] = useState(true);
  const [activeTab, setActiveTab] = useState(ENewsLetterStatus.All);
  const [data, setData] = useState<INewsLetter[]>([]);
  const [showPreview, setShowPreview] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSearchAndFilter, setShowSearchAndFilter] = useState(true);

  const [selectedNewsLetter, setSelectedNewsLetter] = useState<INewsLetter | null>(null);
  const [pagination, setPagination] = useState<PaginationObject>({
    count: 0,
    page: 1,
    pages: 1,
    size: 10
  });
  const [fetchPagination, setFetchPagination] = useState({
    page: 1,
    size: 5,
    status: ENewsLetterStatus.All,
    search: '',
    sort: 'asc' as 'asc' | 'desc'
  });
  const [sendNow, setSendNow] = useState(false);
  const [addedNewsLetter, setAddedNewsLetter] = useState<boolean>(false);
  const [content, setContent] = useState<string>('');

  useEffect(() => {
    document.title = `Newsletters - Recess Admin`;
    if (role !== 'school') navigate('/');
    setShowLineLoader(true);
    newsLettersService.getSchoolNewsLetters(
      {
        school_id: user?.uid ?? '',
        year: new Date().getFullYear(),
        status: activeTab
      },
      res => {
        const filteredData = res.data.filter(item =>
          activeTab === ENewsLetterStatus.All ? true : item.status === activeTab
        );
        setData(filteredData);
        setSelectedNewsLetter(filteredData[0]);
        setPagination(res.pagination);
        setShowLineLoader(false);
        setFetching(false);
        setAddedNewsLetter(false);
      }
    );
  }, [setShowLineLoader, navigate, fetchPagination, activeTab, user?.uid, role, addedNewsLetter]);

  useEffect(() => {
    if (selectedNewsLetter) {
      setContent(selectedNewsLetter.content);
    }
  }, [selectedNewsLetter]);

  const updateData = (id: string, newsletter: INewsLetter) => {
    const newData = data.map(item => {
      if (item.id === id) {
        return {
          ...item,
          ...newsletter
        };
      }
      return item;
    });
    const filterByStatus =
      activeTab === ENewsLetterStatus.All ? newData : newData.filter(item => item.status === activeTab);
    setData(filterByStatus);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const { title, sendRadio, files } = event.target;
      if (title.value) {
        setShowLineLoader(true);
        const fileItems = Array.from(files.files) as File[];
        if (fileItems.length > 0) {
          await coreService.uploadFiles(fileItems, user?.uid ?? '', 'newsletters', 'files', async res => {
            try {
              if (!res.uploading) {
                await newsLettersService.createNewsletter(
                  {
                    school_id: user?.uid ?? '',
                    title: title.value,
                    content,
                    publish: sendRadio.value === 'on',
                    attachments: res.files
                  },
                  res => {
                    setShowLineLoader(false);
                    if (res.data) setContent('');
                    setShowAlert({
                      title: res.data ? 'Success' : 'Error',
                      message: res.message,
                      type: res.data ? 'success' : 'warning',
                      show: true
                    });
                    setAddedNewsLetter(res.data ? true : false);
                    if (res) event.target.reset();
                  }
                );
              }
            } catch (error: any) {
              setShowAlert({
                title: 'Error',
                message: error.message ?? 'Error creating newsletter',
                type: 'warning',
                show: true
              });
            }
          });
        } else {
          await newsLettersService.createNewsletter(
            {
              school_id: user?.uid ?? '',
              title: title.value,
              content,
              publish: sendRadio.value === 'on',
              attachments: []
            },
            res => {
              setShowLineLoader(false);
              setShowAlert({
                title: res.data ? 'Success' : 'Error',
                message: res.message,
                type: res.data ? 'success' : 'warning',
                show: true
              });
              setAddedNewsLetter(res.data ? true : false);
              if (res.data) event.target.reset();
            }
          );
        }
      }
    } catch (error: any) {
      setShowAlert({
        title: 'Error',
        message: error.message ?? 'Error creating newsletter',
        type: 'warning',
        show: true
      });
    }
  };

  const handleUpdateStatus = async (status: ENewsLetterStatus) => {
    if (selectedNewsLetter) {
      setShowLineLoader(true);
      await newsLettersService.updateNewsletter(
        selectedNewsLetter.id,
        {
          status,
          year: new Date(selectedNewsLetter.date_created.date).getFullYear(),
          school_id: selectedNewsLetter.school_id ?? ''
        },
        res => {
          setShowLineLoader(false);
          setShowAlert({
            title: res.data ? 'Success' : 'Error',
            message: res.message,
            type: res.data ? 'success' : 'warning',
            show: true
          });
          if (res.data) {
            updateData(selectedNewsLetter.id, {
              ...selectedNewsLetter,
              status
            });
            setShowPreview(false);
          }
        }
      );
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;

    setFetching(true);
    setFetchPagination(prev => ({
      ...prev,
      page: 1,
      search: term
    }));

    setSearchTerm(term);
  };

  return (
    <Layout
      back={{
        label: 'Back to home',
        link: '/'
      }}
      className=""
    >
      <section className="news">
        <div className="news-create">
          <form className="news-create-form" onSubmit={handleSubmit}>
            <div className="news-create-title">Create a Newsletter</div>
            <div className="form-group">
              <label htmlFor="title" className="form-label">
                Title
              </label>
              <input name="title" className="form-input" id="title" required />
            </div>
            <div className="form-group">
              <label htmlFor="content" className="form-label">
                Content
              </label>
              <ReactQuill
                placeholder="e.g Hello Parents, we are excited to inform you that..."
                theme="snow"
                value={content}
                onChange={setContent}
              />
            </div>
            <div className="form-group">
              <label htmlFor="files" className="form-label">
                Attachments
              </label>
              <input type="file" name="files" className="form-input" id="files" multiple />
            </div>
            <div className="form-group">
              <label htmlFor="sendRadio" className="form-label">
                Do you want to send this newsletter now?
              </label>
              <div className="form-group-radio">
                <div className="form-group-radio-item">
                  <input onChange={() => setSendNow(true)} type="radio" name="sendRadio" id="sendNow" required />
                  <label htmlFor="sendNow">Yes</label>
                </div>
                <div className="form-group-radio-item">
                  <input
                    onChange={() => setSendNow(false)}
                    type="radio"
                    name="sendRadio"
                    id="saveDraft"
                    value={'off'}
                    required
                  />
                  <label htmlFor="saveDraft">No</label>
                </div>
              </div>
            </div>
            <div className="form-group-btns">
              <Button type="submit">{sendNow ? 'Send' : 'Save'}</Button>
            </div>
          </form>
        </div>
        <section className="news-listing">
          <TabNav className="news-header-tabs" aria-label="Main">
            {TABS.map(tab => (
              <TabNav.Link
                onClick={() => {
                  setActiveTab(tab.id);
                  setShowPreview(false);
                  setShowSearchAndFilter(true);
                }}
                key={tab.id}
                selected={activeTab === tab.id}
                className={activeTab === tab.id ? 'tab-active' : ''}
                sx={{
                  color: activeTab === tab.id ? '#1cbc96' : '#000',
                  fontWeight: activeTab === tab.id ? 'bold' : 'normal',
                  cursor: 'pointer'
                }}
              >
                {tab.label}
              </TabNav.Link>
            ))}
          </TabNav>
          <section className="news-body">
            {showSearchAndFilter && (searchTerm || pagination.pages > 1) && (
              <div className="search-filter-sort-container">
                <Search onChange={handleSearchChange} value={searchTerm} />
                <div className="search-filter-ctas">
                  {activeTab === ENewsLetterStatus.All ? (
                    <div className="filter-by-status">
                      <label htmlFor="status">Status: </label>
                      <select
                        id="status"
                        name="status"
                        className="form-control table-select"
                        value={fetchPagination.status}
                        onChange={
                          e => console.log(e.target.value)
                          // setFetchPagination({ ...fetchPagination, status: e.target.value as ENewsLetterStatus })
                        }
                      >
                        <option value={ENewsLetterStatus.All}>All</option>
                        <option value={ENewsLetterStatus.Active}>Sent</option>
                        <option value={ENewsLetterStatus.Draft}>Draft</option>
                      </select>
                    </div>
                  ) : null}

                  <div className="sort-container">
                    <label htmlFor="sort">Sort: </label>
                    <select
                      id="sort"
                      name="sort"
                      className="form-control table-select"
                      value={fetchPagination.sort}
                      onChange={e => setFetchPagination({ ...fetchPagination, sort: e.target.value as 'asc' | 'desc' })}
                    >
                      <option value="asc">Ascending</option>
                      <option value="desc">Descending</option>
                    </select>
                  </div>
                </div>
              </div>
            )}
            {fetching ? (
              <NewsLettersSkeleton />
            ) : data.length === 0 ? (
              <NoData message="Sorry, no newsletters found" />
            ) : (
              <section className="news-listing-items">
                {showPreview ? (
                  <div className="news-preview">
                    <form
                      onSubmit={async (event: any) => {
                        event.preventDefault();
                        try {
                          const { title } = event.target;
                          setShowLineLoader(true);
                          if (title.value && selectedNewsLetter) {
                            await newsLettersService.updateNewsletter(
                              selectedNewsLetter.id,
                              {
                                year: new Date(selectedNewsLetter.date_created.date).getFullYear(),
                                school_id: user?.uid ?? '',
                                title: title.value,
                                content,
                                attachments: selectedNewsLetter.attachments
                              },
                              res => {
                                setShowLineLoader(false);
                                setShowPreview(res.data ? false : true);
                                if (res.data) {
                                  updateData(selectedNewsLetter.id, {
                                    ...selectedNewsLetter,
                                    title: title.value,
                                    content
                                  });
                                }
                                setShowAlert({
                                  title: res.data ? 'Success' : 'Error',
                                  message: res.message,
                                  type: res.data ? 'success' : 'warning',
                                  show: true
                                });
                              }
                            );
                          } else {
                            setShowLineLoader(false);
                            setShowAlert({
                              title: 'Error',
                              message: 'Please fill all fields to update newsletter',
                              type: 'warning',
                              show: true
                            });
                          }
                        } catch (error: any) {
                          setShowAlert({
                            title: 'Error',
                            message: error.message ?? 'Error updating newsletter',
                            type: 'warning',
                            show: true
                          });
                        }
                      }}
                    >
                      <div className="news-preview-header">
                        <div className="news-preview-header-title">
                          <Button onClick={() => setShowPreview(false)}>
                            <ArrowLeftIcon />
                          </Button>
                          <span className="news-preview-header-title-text"> Edit & Update Newsletter</span>
                        </div>
                        <div className="news-preview-date">
                          <span className="news-preview-date-label">Date Created : </span>
                          <span className="news-preview-date-value">{selectedNewsLetter?.date_created.long_date}</span>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="title" className="form-label">
                          Title
                        </label>
                        <input
                          name="title"
                          className="form-input"
                          id="title"
                          required
                          defaultValue={selectedNewsLetter?.title}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="content" className="form-label">
                          Content
                        </label>
                        <ReactQuill
                          placeholder="e.g Hello Parents, we are excited to inform you that..."
                          theme="snow"
                          value={content}
                          onChange={setContent}
                        />
                      </div>
                      <div className="form-group-btns">
                        <Button type="submit">Update</Button>
                        {selectedNewsLetter?.status === ENewsLetterStatus.Draft && (
                          <Button onClick={() => handleUpdateStatus(ENewsLetterStatus.Active)}>Publish</Button>
                        )}
                      </div>
                    </form>
                  </div>
                ) : (
                  <>
                    {data.map(item => (
                      <NewsLetterCard
                        key={item.id}
                        data={item}
                        onClick={() => {
                          setSelectedNewsLetter(item);
                          setShowPreview(true);
                        }}
                      />
                    ))}
                    {pagination.pages > 1 && (
                      <div className="news-pagination">
                        <Pagination
                          showPages={true}
                          pageCount={pagination.pages}
                          currentPage={pagination.page}
                          marginPageCount={2}
                          surroundingPageCount={2}
                          onPageChange={(event, currentPage) => {
                            event.preventDefault();
                            setFetchPagination(prev => ({
                              ...prev,
                              page: currentPage
                            }));
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
              </section>
            )}
          </section>
        </section>
      </section>
    </Layout>
  );
};

export default NewsLetters;
