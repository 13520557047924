import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import { GeneralStatsSkeleton } from 'components/skeletons';
import { Layout } from 'layouts';
import { useEffect, useState } from 'react';
import { Line, Pie } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import { statsService } from 'services';
import { GeneralStats, MonthlyStats, StudentStats } from 'services/stats/index.models';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ['Female', 'Male', 'Others'],
  datasets: [
    {
      label: 'Students',
      data: [0, 0, 0],
      backgroundColor: ['rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)'],
      borderColor: ['rgba(75, 192, 192, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)'],
      borderWidth: 1
    }
  ]
};

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart'
    }
  }
};

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
export const lineOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const
    },
    title: {
      display: true,
      text: `Monthly Stats - ${new Date().getFullYear()}`
    }
  }
};

export const lineData = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June'],
  datasets: [
    {
      label: 'Students',
      data: [],
      borderColor: 'rgba(54, 162, 235, 1)',
      backgroundColor: 'rgba(54, 162, 235, 0.2)'
    },
    {
      label: 'Parents',
      data: [],
      borderColor: 'rgba(255, 206, 86, 1)',
      backgroundColor: 'rgba(255, 206, 86, 0.2)'
    },
    {
      label: 'Teachers',
      data: [],
      borderColor: 'rgba(255, 99, 132, 1)',
      backgroundColor: 'rgba(255, 99, 132, 0.2)'
    },
    {
      label: 'Schools',
      data: [],
      borderColor: 'rgba(75, 192, 192, 1)',
      backgroundColor: 'rgba(75, 192, 192, 0.2)'
    }
  ]
};

export const AdminHome = () => {
  const [generalStats, setGeneralStats] = useState<GeneralStats>({
    students: 0,
    schools: 0,
    parents: 0,
    teachers: 0,
    homeworks: 0,
    diaries: 0,
    notes: 0,
    newsletters: 0
  });
  const [monthlyStats, setMonthlyStats] = useState<MonthlyStats>({
    schools: [],
    classes: [],
    students: [],
    parents: [],
    teachers: []
  });
  const [studentsStats, setStudentsStats] = useState<StudentStats>({
    total: 0,
    male: 0,
    female: 0,
    other: 0,
    none: 0
  });
  const [loaders, setLoaders] = useState({
    generalStats: true,
    monthlyStats: true,
    studentsStats: true,
    parentsStats: true
  });

  useEffect(() => {
    (async () => {
      try {
        await statsService.getGeneralStats(data => {
          if (data) setGeneralStats(data);
          setLoaders(prev => ({ ...prev, generalStats: false }));
        });

        await statsService.getMonthlyStats(data => {
          if (data) setMonthlyStats(data);
          setLoaders(prev => ({ ...prev, monthlyStats: false }));
        });
        await statsService.getAllStudentsStats(data => {
          if (data) setStudentsStats(data);
          setLoaders(prev => ({ ...prev, studentsStats: false }));
        });
      } catch (error) {
        if (error instanceof Error) toast.error(error.message);
      }
    })();
  }, []);
  return (
    <Layout className="home">
      <section className="home-content">
        <section className="stats">
          <div className="stats-title">General Stats</div>
          {loaders.generalStats ? (
            <GeneralStatsSkeleton />
          ) : (
            <section className="dashboard-data">
              <div className="dashboard-data-card">
                <div className="dashboard-data-card-title">Enrolled Schools</div>
                <div className="dashboard-data-card-value">{generalStats?.schools}</div>
              </div>
              <div className="dashboard-data-card">
                <div className="dashboard-data-card-title">Enrolled Teachers</div>
                <div className="dashboard-data-card-value">{generalStats?.teachers}</div>
              </div>
              <div className="dashboard-data-card">
                <div className="dashboard-data-card-title">Enrolled Students</div>
                <div className="dashboard-data-card-value">{generalStats?.students}</div>
              </div>
              <div className="dashboard-data-card">
                <div className="dashboard-data-card-title">Enrolled Parents</div>
                <div className="dashboard-data-card-value">{generalStats?.parents}</div>
              </div>
              <div className="dashboard-data-card">
                <div className="dashboard-data-card-title">Homeworks</div>
                <div className="dashboard-data-card-value">{generalStats?.homeworks}</div>
              </div>
              <div className="dashboard-data-card">
                <div className="dashboard-data-card-title">Diaries</div>
                <div className="dashboard-data-card-value">{generalStats?.diaries}</div>
              </div>
              <div className="dashboard-data-card">
                <div className="dashboard-data-card-title">Behavioural Notes</div>
                <div className="dashboard-data-card-value">{generalStats?.notes}</div>
              </div>
              <div className="dashboard-data-card">
                <div className="dashboard-data-card-title">Newsletters</div>
                <div className="dashboard-data-card-value">{generalStats?.newsletters}</div>
              </div>
            </section>
          )}
        </section>
        <section className="stats">
          <div className="stats-title">Enrollment Stats</div>
          <section className="stats-charts">
            <div className="stats-chart">
              <Line
                options={lineOptions}
                data={{
                  ...lineData,
                  labels: monthlyStats.students.map(m => m.month),
                  datasets: [
                    {
                      ...lineData.datasets[0],
                      data: monthlyStats.students.map(m => m.total)
                    },
                    {
                      ...lineData.datasets[1],
                      data: monthlyStats.parents.map(m => m.total)
                    },
                    {
                      ...lineData.datasets[2],
                      data: monthlyStats.teachers.map(m => m.total)
                    },
                    {
                      ...lineData.datasets[3],
                      data: monthlyStats.schools.map(m => m.total)
                    }
                  ]
                }}
              />
            </div>
            <div className="stats-chart pie-chart">
              <div className="stats-chart-subtitle">Students(Gender Stats)</div>
              <Pie
                data={{
                  ...data,
                  datasets: [
                    {
                      ...data.datasets[0],
                      data: [studentsStats.female, studentsStats.male, studentsStats.none + studentsStats.other]
                    }
                  ]
                }}
              />
            </div>
          </section>
        </section>
      </section>
    </Layout>
  );
};
