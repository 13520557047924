import axios from 'axios';
import { PaginationObject } from 'models/index.model';
import { SharedService } from 'services/shared';
import coreService from '../core';
import {
  ENewsLetterStatus,
  INewsLetter,
  INewsLetterCreate,
  INewsLetterUpdatePayload,
  INewsLetterUpdateResponse
} from './index.models';

class NewsLettersService extends SharedService {
  async getSchoolNewsLetters(
    payload: {
      school_id: string;
      year: number;
      status: ENewsLetterStatus;
    },
    callback: (res: { data: INewsLetter[]; pagination: PaginationObject }) => void
  ) {
    try {
      const idToken = await coreService.getIdToken();
      const res = await axios.get(`${this.getApiBase()}/newsletters/`, {
        headers: {
          Authorization: `Bearer ${idToken}`
        },
        params: {
          school_id: payload.school_id,
          year: payload.year,
          status: payload.status
        }
      });
      if (res.data.data)
        callback({
          data: res.data.data ?? [],
          pagination: res.data.pagination ?? {
            count: 0,
            page: 1,
            pages: 1,
            size: 10
          }
        });
      else throw new Error(res.data.message);
    } catch (error) {
      throw error;
    }
  }

  async getNewsletter(school_id: string, id: string, callback: (data: INewsLetter) => void) {
    try {
      const idToken = await coreService.getIdToken();
      const res = await axios.get(`${this.getApiBase()}/newsletters/${id}`, {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      });
      callback(res.data.data);
    } catch (error) {
      throw error;
    }
  }

  async createNewsletter(newsletter: INewsLetterCreate, callback: (data: INewsLetterUpdateResponse) => void) {
    try {
      const idToken = await coreService.getIdToken();
      const res = await axios.post<INewsLetterUpdateResponse>(`${this.getApiBase()}/newsletters`, newsletter, {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      });
      callback(res.data);
    } catch (error) {
      throw error;
    }
  }

  async updateNewsletter(
    id: string,
    newsletter: INewsLetterUpdatePayload,
    callback: (data: INewsLetterUpdateResponse) => void
  ) {
    try {
      const idToken = await coreService.getIdToken();
      const res = await axios.patch<INewsLetterUpdateResponse>(`${this.getApiBase()}/newsletters/${id}`, newsletter, {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      });
      callback(res.data);
    } catch (error) {
      throw error;
    }
  }

  async fetchNewsletterViews(newsletterId: string, callback: (views: number) => void) {
    try {
      const idToken = await coreService.getIdToken();
      const res = await axios.get(`${this.getApiBase()}/newsletter/views`, {
        headers: {
          Authorization: `Bearer ${idToken}`
        },
        params: {
          id: newsletterId
        }
      });
      callback(res.data.views);
    } catch (error) {
      throw error;
    }
  }
}

const newsLettersService = new NewsLettersService();
export default newsLettersService;
