import NoDataIcon from 'assets/images/icons/no_data.svg';
import styled from 'styled-components';
 const StyledNoData = styled.section`
   .no-data {
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     gap: 10px;
     padding: 25px 0;
     img {
       max-width: 75px;
     }
     p {
     font-size: 14px;
   }
   }

 `;

const NoData = ({ message = 'No data found' }) => {
  return (
    <StyledNoData>
      <section className="no-data">
        <img src={NoDataIcon} alt="No data found" />
        <p>{message}</p>
      </section>
    </StyledNoData>
  );
};

export default NoData;
