import { SearchIcon } from '@primer/octicons-react';
import styled from 'styled-components';

const StyledSearchInput = styled.div`
  .app-search {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    padding: 0 26px;
    background: #fff;
    border: 1px solid #cacaca;
    box-sizing: border-box;
    border-radius: 4px;
    flex: 1 1;
    max-width: 400px;
    
    &-input {
      flex: 1 1;
      max-width: 100%;
      border: none;
      outline: none;
      padding: 10px 15px;
      padding-left: 0;
      font-size: 14px;
      font-weight: 300;
      color: #333;
      background: transparent;
      box-sizing: border-box;
    }
  }

  @media screen and (max-width: 2000px) {
    .app-search {
      max-width: 33%;
    }
  }
`;
const SearchInput = ({
  onChange = () => {},
  placeholder = 'Search...',
  defaultValue = ''
}: {
  onChange?: (event: any) => void;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
}) => {
  return (
    <StyledSearchInput>
      <div className="app-search">
        <input className="app-search-input" type="text" onChange={onChange} placeholder={placeholder} />
        <SearchIcon />
      </div>
    </StyledSearchInput>
  );
};

export default SearchInput;
